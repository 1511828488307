<template>
    <div class="page-container flex flex-col ste">
        <header class="bg-gray-100 border-b border-[#030b12]/10">
            <div class="max-w-[1980px] mx-auto">
                <div class="flex items-center px-6 py-2 h-14">
                    <div class="flex items-center gap-2">
                        <FaIcon icon="fas fa-circle-user" ></FaIcon>
                        <h1 class="text-surface-800 text-xl font-semibold leading-7">Account</h1>
                    </div>
                </div>
            </div>
        </header>

        <div class="max-w-[1240px] mx-auto px-12 py-6 flex-1 w-full">
            <ProfileSection />
            <PasswordSection />
            <UserManagementSection v-if="showUserManagement" />
        </div>

        <footer class="self-end bg-gray-100 border-b border-[#030b12]/10 px-12 py-4 w-full sticky bottom-0">
            <Button
                label="Privacy Policy"
                link
                size="small"
                class="text-xs"
                icon="far fa-external-link"
                icon-pos="right"
                @click="openPrivacyPolicy"
            />
        </footer>
    </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/useUserStore'
import { computed } from 'vue'
import ProfileSection from '@/components/account/ProfileSection.vue'
import PasswordSection from '@/components/account/PasswordSection.vue'
import UserManagementSection from '@/components/account/UserManagementSection.vue'
import { storeToRefs } from 'pinia'

const userStore = useUserStore()
const { isThirdParty, isSubmitter, isVendor } = storeToRefs(userStore)

const showUserManagement = computed(() => {
    return !isThirdParty.value && !isSubmitter.value && !isVendor.value
})

const openPrivacyPolicy = (): void => {
    window.open('/about/privacy-policy', '_blank')
}
</script>
