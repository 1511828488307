<template>
    <SharedLayout :promo-url="promoUrl">
        <router-view v-if="$route.name !== 'LoginPage'"></router-view>
        <template v-else>
            <div class="bg-white p-8 m-8 rounded-lg shadow-md w-96">
                <div class="flex justify-center mb-6">
                    <img src="@/assets/images/performline-logo-blue.png" alt="Performline" class="h-12" />
                </div>

                <div v-if="loginError" class="bg-red-500/20 text-red-600 text-left mb-4 px-4 py-2 text-sm">
                    {{ loginError }}
                </div>

                <form class="w-full" @submit.prevent>
                    <div class="mb-4">
                        <label for="username" class="block text-gray-600 mb-2 text-sm font-semibold">Username</label>
                        <input
                            id="username"
                            v-model="username"
                            type="text"
                            required
                            class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                    </div>
                    <div class="mb-4">
                        <label for="password" class="block text-gray-600 mb-2 text-sm font-semibold">Password</label>
                        <div class="relative">
                            <input
                                id="password"
                                v-model="password"
                                :type="showPassword ? 'text' : 'password'"
                                required
                                class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                            />
                            <button
                                type="button"
                                class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                @click="togglePasswordVisibility"
                            >
                                <FaIcon :icon="showPassword ? 'far fa-eye-slash' : 'far fa-eye'"></FaIcon>
                            </button>
                        </div>
                    </div>

                    <div class="flex justify-between mb-6 text-sm">
                        <router-link to="/login/password-reset" class="text-blue-500 hover:underline">
                            <FaIcon icon="far fa-lock-keyhole"></FaIcon>
                            Forgot password?</router-link
                        >
                        <a
                            href="https://lp.performline.com/referral-program?utm_campaign=Referral Program&utm_source=pl-login"
                            class="text-blue-500 hover:underline"
                        >
                            <FaIcon icon="far fa-gift"></FaIcon>
                            Make a Referral</a
                        >
                    </div>

                    <Button
                        rounded
                        data-testid="btn-login"
                        type="submit"
                        class="w-full text-white py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        :disabled="!canSubmit"
                        @click="handleSubmit"
                    >
                        {{ isSubmitting ? 'Logging in...' : 'Login' }}
                    </Button>
                </form>
            </div>
        </template>
    </SharedLayout>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import SharedLayout from './SharedLayout.vue'
import { useUserStore } from '@/stores/useUserStore'
import { useRouter } from 'vue-router'

const router = useRouter()
const userStore = useUserStore()

const username = ref('')
const password = ref('')
const loginError = ref('')
const isSubmitting = ref(false)
const showPassword = ref(false)
const promoUrl = 'https://lp.performline.com/login-page-promotion-7489jwiys28f'

const canSubmit = computed(() => !isSubmitting.value && username.value && password.value)

const togglePasswordVisibility = (): void => {
    showPassword.value = !showPassword.value
}

const handleSubmit = async (): Promise<void> => {
    if (username.value && password.value) {
        loginError.value = ''
        isSubmitting.value = true
        try {
            const response = await userStore.loginUser(username.value, password.value)
            if (response.success) {
                window.location.href = '/'
            } else {
                loginError.value = response.message
            }
        } catch (error: any) {
            console.log(error)
            if (error.message.includes('expired')) {
                router.push({
                    name: 'PasswordReset',
                    query: { error: error.message }
                })
            } else {
                loginError.value = error.message
            }
        } finally {
            isSubmitting.value = false
        }
    }
}
</script>
