import httpClient from './httpClient'
import {
    TagTableOrderByOptions
} from '@/interfaces/Tag.types'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'
import type { DeleteContentTagApiResponse, GetContentTagSummariesApiResponse, GetTagSummariesApiParams, PostContentTagApiBody, PostContentTagApiResponse, PutContentTagApiBody, PutContentTagApiResponse } from './interfaces/Tags.api.types'

const getTags = async (brandId: string, params?: GetTagSummariesApiParams): Promise<GetContentTagSummariesApiResponse> => {
    // To be removed after API update
    if (params?.orderBy) {
        if (params.orderBy === 'otherBrandCount') {
            params.orderBy = TagTableOrderByOptions.OtherBrandCountAsc
        } else if (params.orderBy === '-otherBrandCount') {
            params.orderBy = TagTableOrderByOptions.OtherBrandCountDesc
        }
    }
    try {
        const response: AxiosResponse<GetContentTagSummariesApiResponse> = await httpClient.get<GetContentTagSummariesApiResponse>(
            `/brands/${brandId}/tag-summaries`,
            {
                params: { ...params }
            }
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const updateTag = async (brandId: string, tagId: number, body: PutContentTagApiBody): Promise<PutContentTagApiResponse> => {
    try {
        const response: AxiosResponse<PutContentTagApiResponse> = await httpClient.put<PutContentTagApiResponse>(
            `/brands/${brandId}/tags/${tagId}`,
            body
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const deleteTag = async (brandId: string, tagId: number): Promise<DeleteContentTagApiResponse> => {
    try {
        const response: AxiosResponse<DeleteContentTagApiResponse> = await httpClient.delete<DeleteContentTagApiResponse>(`/brands/${brandId}/tags/${tagId}`)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const createTag = async (brandId: string, body: PostContentTagApiBody): Promise<PostContentTagApiResponse> => {
    try {
        const response: AxiosResponse<PostContentTagApiResponse> = await httpClient.post<PostContentTagApiResponse>(
            `/brands/${brandId}/tags`,
            body
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default { getTags, updateTag, deleteTag, createTag }
