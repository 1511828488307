<template>
    <RouterView />
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import { appDivId } from './config'
import { usePreferences } from '@/composables/usePreferences'

const { toggleTheme } = usePreferences({ divId: appDivId })

import { onMounted, onUnmounted } from 'vue'

function handleKeydown(event: KeyboardEvent): void {
    if (event.ctrlKey && event.code === 'KeyD') {
        toggleTheme()
    }
}

onMounted(() => {
    window.addEventListener('keydown', handleKeydown)
})

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeydown)
})
</script>