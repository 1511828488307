import { createRouter, createWebHistory, type Router } from 'vue-router'
import PrivacyPolicy from './PrivacyPolicy.vue'
import ThirdParties from './ThirdParties.vue'

export const aboutViewRoutes = [
    {
        path: '/about/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: { requiresAuth: true }
    },
    {
        path: '/about/thirdparties',
        name: 'ThirdParties',
        component: ThirdParties,
        meta: { requiresAuth: true }
    }
]

export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'AboutView') {
    router = createRouter({
        history: createWebHistory(),
        routes: aboutViewRoutes
    })
}
