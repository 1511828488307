<template>
    <div class="w-full h-screen flex justify-center items-center bg-gray-100">
        <div class="bg-white p-8 rounded-lg shadow-md w-[500px]">
            <div class="flex justify-center mb-6">
                <img src="@/assets/images/performline-logo-blue.png" alt="Performline" class="h-12" />
            </div>

            <div v-if="signupError" class="bg-red-500/20 text-red-600 text-left mb-4 px-4 py-2 text-sm">
                {{ signupError }}
            </div>

            <form class="w-full" @submit.prevent>
                <div class="mb-4">
                    <label for="username" class="block text-gray-600 mb-2 text-sm font-semibold">Username</label>
                    <input
                        id="username"
                        v-model="username"
                        :placeholder="isLoadingUsername ? 'Loading...' : ''"
                        disabled
                        type="text"
                        required
                        class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 bg-gray-200 cursor-not-allowed"
                        :class="{
                            'animate-pulse': isLoadingUsername
                        }"
                    />
                </div>
                <div class="mb-4">
                    <label for="firstName" class="block text-gray-600 mb-2 text-sm font-semibold">First Name</label>
                    <input
                        id="firstName"
                        v-model="firstName"
                        type="text"
                        required
                        class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>
                <div class="mb-4">
                    <label for="lastName" class="block text-gray-600 mb-2 text-sm font-semibold">Last Name</label>
                    <input
                        id="lastName"
                        v-model="lastName"
                        type="text"
                        required
                        class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>
                <div class="mb-4">
                    <label for="password" class="block text-gray-600 mb-2 text-sm font-semibold">Password</label>
                    <div class="relative">
                        <input
                            id="password"
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            required
                            class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                        <button
                            type="button"
                            class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                            @click="togglePasswordVisibility"
                        >
                            <FaIcon :icon="showPassword ? 'far fa-eye-slash' : 'far fa-eye'"></FaIcon>
                        </button>
                    </div>
                    <div v-if="passwordHasError" class="flex py-2">
                        <div class="p-2 rounded border border-gray-200 w-full">
                            <p class="font-medium mb-2 text-sm">Passwords must include at least:</p>
                            <ul class="list-disc list-inside ml-1 text-sm">
                                <li :class="[hasMinLength ? 'text-green-800' : 'text-red-500']">8 characters</li>
                                <li :class="[hasLowerCase ? 'text-green-800' : 'text-red-500']">1 lowercase letter</li>
                                <li :class="[hasUpperCase ? 'text-green-800' : 'text-red-500']">1 uppercase letter</li>
                                <li :class="[hasNumber ? 'text-green-800' : 'text-red-500']">1 number</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <label for="companyName" class="block text-gray-600 mb-2 text-sm font-semibold">Company Name</label>
                    <input
                        id="companyName"
                        v-model="companyName"
                        type="text"
                        required
                        class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                </div>

                <div class="flex justify-center mb-6 text-sm whitespace-pre">
                    If you already have an account,
                    <router-link to="/login" class="text-blue-500 hover:underline"> log in here.</router-link>
                </div>

                <Button
                    rounded
                    data-testid="btn-signup-vendor"
                    type="submit"
                    class="w-full text-white py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    :disabled="!canSubmit"
                    @click="handleSubmit"
                >
                    {{ isSubmitting ? 'Submitting...' : 'Submit' }}
                </Button>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useVendorStore } from '@/stores/useVendorStore'
import { useRoute } from 'vue-router'
import type { UrlQuery } from '@/interfaces/Common.types'

const username = ref<string>('')
const firstName = ref<string>('')
const lastName = ref<string>('')
const password = ref<string>('')
const companyName = ref<string>('')
const showPassword = ref<boolean>(false)
const isSubmitting = ref<boolean>(false)
const signupError = ref<string | null>(null)
const passwordHasError = ref<boolean>(false)
const isLoadingUsername = ref<boolean>(true)

const hasMinLength = computed(() => password.value.length >= 8)
const hasLowerCase = computed(() => /[a-z]/.test(password.value))
const hasUpperCase = computed(() => /[A-Z]/.test(password.value))
const hasNumber = computed(() => /\d/.test(password.value))

const isPasswordValid = computed(
    () => password.value && hasMinLength.value && hasLowerCase.value && hasUpperCase.value && hasNumber.value
)

const vendorStore = useVendorStore()

const route = useRoute()

const { caseId, emailId } = route.params as UrlQuery

onMounted(async () => {
    try {
        const resp = await vendorStore.getVendorEmail(caseId, emailId)
        username.value = resp.email
        isLoadingUsername.value = false
    } catch (error) {
        console.error('Error fetching user data:', error)
        signupError.value = 'Error fetching username'
        isLoadingUsername.value = false
    }
})

const canSubmit = computed((): boolean => {
    return username.value.length > 0 && password.value.length > 0
})

const togglePasswordVisibility = (): void => {
    showPassword.value = !showPassword.value
}

const handleSubmit = async (): Promise<void> => {
    isSubmitting.value = true
    signupError.value = null

    try {
        if (!isPasswordValid.value) {
            signupError.value = 'Password does not meet the requirements'
            passwordHasError.value = true
            isSubmitting.value = false
            return
        }
        passwordHasError.value = false
        if (!caseId || !emailId) {
            signupError.value = 'Invalid case ID or email ID'
            isSubmitting.value = false
            return
        }
        const resp = await vendorStore.signUpVendor(caseId, emailId, {
            username: username.value,
            firstName: firstName.value,
            lastName: lastName.value,
            password: password.value,
            companyName: companyName.value
        })
        if (resp.success) {
            window.location.href = '/login/'
        } else {
            signupError.value = resp.message || 'Failed to sign up vendor'
        }
    } catch (error: any) {
        signupError.value = error?.error ? error.error as string : 'Failed to sign up vendor'
    } finally {
        isSubmitting.value = false
    }
}
</script>
