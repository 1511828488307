import httpClient from './httpClient'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'
import type { GetUserManagementApiParams, GetUserManagementUsersApiResp, GetUserManagementUsersSummaryApiResp, PatchUserManagementUsersApiParams } from './interfaces/UserManagement.api.types'

const fetchUsers = async (companyId: string, params?: GetUserManagementApiParams): Promise<GetUserManagementUsersApiResp> => {
    try {
        const response: AxiosResponse<GetUserManagementUsersApiResp> = await httpClient.get<GetUserManagementUsersApiResp>(
            `/companies/${companyId}/users`,
            {
                params: { ...params }
            }
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const patchUser = async (username: string, payload: PatchUserManagementUsersApiParams): Promise<void> => {
    try {
        await httpClient.patch<void>(`/users/${username}`, payload)
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const fetchUsersSummary = async (companyId: string, params?: GetUserManagementApiParams): Promise<GetUserManagementUsersSummaryApiResp> => {
    try {
        const response: AxiosResponse<GetUserManagementUsersSummaryApiResp> = await httpClient.get<GetUserManagementUsersSummaryApiResp>(
            `/companies/${companyId}/users/summary`,
            {
                params: { ...params }
            }
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const exportUsers = async (companyId: string, params?: GetUserManagementApiParams): Promise<string> => {
    try {
        const response: AxiosResponse<string> = await httpClient.get<string>(
            `/companies/${companyId}/users/export`,
            {
                params: { ...params },
                responseType: 'text'
            }
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default { fetchUsers, patchUser, fetchUsersSummary, exportUsers }
