<template>
    <div id="tag-name-cell" class="flex font-medium items-center justify-between">
        <span
            v-if="editTag.id !== tagSummary.tag.id"
            v-tooltip.top="{ value: tagSummary.tag.name, showDelay: 500 }"
            :class="{
                truncate: !isTagInEditMode,
                'hover:cursor-pointer': !loading
            }"
            @click.stop="tagHasOtherBranches ? showAssetsWithTag(tagSummary.tag.id) : null"
        >
            {{ tagSummary.tag.name }}
        </span>
        <div v-else :id="'edit-tag-name-' + editTag.name + editTag.id">
            <Textarea
                :id="'edit-tag-name-' + editTag.name + editTag.id"
                v-model="editTag.name"
                v-focus
                auto-resize
                rows="1"
                :pt="{
                    root: { class: ['!border-none !text-primary-500 resize-none focus:ring-0 !p-0 !bg-transparent'] }
                }"
                @blur="exitTagEdit"
                @keydown.enter.prevent="exitTagEdit"
                @keydown.esc="cancelTagEdit"
            />
        </div>
        <div v-if="showHoverOptions" class="tag-controls ml-auto font-medium gap-2">
            <Button
                class="focus:outline-none !p-1 !w-6 !h-6 rounded-[8px] hover:border-surface-950/[.12] hover:border"
                text
                rounded
                size="small"
                @click.stop="enableTagEdit(tagSummary.tag)"
            >
                <FaIcon icon="far fa-pen" class="text-surface-800 !w-4 !h-4"></FaIcon>
            </Button>
            <Button
                ref="tagDeleteButton"
                class="focus:outline-none !p-1 !w-6 !h-6 rounded-[8px] hover:border-surface-950/[.12] hover:border"
                size="small"
                text
                rounded
                @click="handleTagDelete($event, tagSummary)"
            >
                <FaIcon icon="far fa-trash-can" class="text-surface-800 !w-4 !h-4"></FaIcon>
            </Button>
            <Button
                v-if="tagHasOtherBranches"
                outlined
                size="small"
                rounded
                class="text-surface-800 border-surface-950/[.12] hover:bg-surface-950/[.06] focus:bg-surface-950[.03] active:bg-brand-500[.12] active:text-brand-500 border rounded-[8px] !py-0.5 !px-2 border-black flex items-center"
                @click.stop="showAssetsWithTag(tagSummary.tag.id)"
            >
                <span class="mr-2 leading-none">Assets</span>
                <FaIcon icon="far fa-arrow-up-right-from-square" size="sm"></FaIcon>
            </Button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ContentTag, PutTagTableUIPayload, TagSummary } from '@/interfaces/Tag.types'
import { storeToRefs } from 'pinia'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { inject, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { dayjsPlugin } from '@/plugins/dayjs'
import type Button from 'primevue/button'
import { defineEmits } from 'vue';
import { useTagStore } from '@/stores/useTagStore'

const emit = defineEmits(['tag-name-updated']);
const props = defineProps<{
    tagSummary: TagSummary
    loading: boolean
}>()

const defaultEditTag: ContentTag = {
    id: 0,
    name: '',
    isActive: false,
    createdAt: ''
}
const editTag = ref<ContentTag>(defaultEditTag)
const tagsStore = useTagStore()
const { tagSummaries } = storeToRefs(tagsStore)

const route = useRoute()
const confirm = useConfirm()
const router = useRouter()
const toast = useToast()

const tagDeleteButton = ref<InstanceType<typeof Button>>()

const dayjs: typeof dayjsPlugin = inject('dayjs') || dayjsPlugin

const brandId = route.params.brandId as string

function enableTagEdit(tag: ContentTag): void {
    editTag.value = { ...tag }
}

const tagHasOtherBranches = computed(() => props.tagSummary.count > 0)

const isTagInEditMode = computed(() => editTag.value.id === props.tagSummary.tag.id)

const showHoverOptions = computed(() => editTag.value.id != props.tagSummary.tag.id)

let isExitingTagEdit = false;
async function exitTagEdit(): Promise<void> {
    if (isExitingTagEdit || !editTag.value.id) {
        return;
    }
    isExitingTagEdit = true;

    editTag.value.name = editTag.value.name.trim();
    if (editTag.value.name) {
        // remove new line
        editTag.value.name = editTag.value.name.replace(/\n/g, ' ')
        // Check for changes
        const index = tagSummaries.value.findIndex((tagSummary) => tagSummary.tag.id == editTag.value.id)
        if (editTag.value.name != tagSummaries.value[index].tag.name) {
            try {
                const payload = { tagId: editTag.value.id, name: editTag.value.name }
                await tagsStore.editTag(brandId, payload as PutTagTableUIPayload)
                toast.add({
                    severity: 'success',
                    summary: 'Confirmed',
                    detail: `Tag edited. New name "${editTag.value.name}".`,
                    life: 3000
                })
                emit('tag-name-updated', editTag.value);
            } catch (err) {
                console.error(err)
                toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: `Tag "${editTag.value.name}" failed to be updated.`,
                    life: 3000
                })
            }
        }
    } else {
        toast.add({ severity: 'info', summary: 'Tag name cannot be empty', life: 3000 })
    }
    editTag.value = defaultEditTag
    isExitingTagEdit = false;
}

async function handleTagDelete(event: Event, tagSummary: TagSummary): Promise<void> {
    confirm.require({
        group: 'confirm-tag-delete',
        target: event.currentTarget as HTMLElement,
        header: 'Delete Tag',
        message: `Delete "${tagSummary.tag.name}" tag?`,
        rejectProps: {
            label: 'Cancel',
            rounded: true,
            text: true,
            size: 'small'
        },
        acceptProps: {
            label: 'Delete',
            severity: 'danger',
            rounded: true,
            size: 'small'
        },
        accept: async () => {
            try {
                await tagsStore.removeTag(brandId, tagSummary.tag.id)
                toast.add({
                    severity: 'success',
                    summary: 'Confirmed',
                    detail: `Deleted "${tagSummary.tag.name}" tag.`,
                    life: 3000
                })
            } catch (err) {
                console.error(`Failed to delete tag with id ${tagSummary.tag.id}.`)
                toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: `Failed to delete "${tagSummary.tag.name}" tag.`,
                    life: 3000
                })
            }
        },
        reject: () => {
            toast.add({ severity: 'info', summary: 'Delete Canceled', life: 3000 })
        }
    })
}

function showAssetsWithTag(tagId: number): void {
    const brandType = window.platformV1Data?.common?.advertiser

    const end_date = dayjs().format('MM-DD-YYYY').split('-').join('/')
    const start_date = dayjs().subtract(90, 'day').format('MM-DD-YYYY').split('-').join('/')

    if (brandType) {
        if (brandType == 'email') {
            router.push({
                path: '/email',
                query: {
                    path: `tag=${tagId}&sort_by=-email__date_sent&date_filter_start=${start_date}&date_filter_end=${end_date}&submit=Submit`
                }
            })
        } else if (brandType == 'callcenter') {
            router.push({
                path: '/callcenter',
                query: {
                    path: `tag=${tagId}&sort_by=-date_created&date_filter_start=${start_date}&date_filter_end=${end_date}&submit=Submit`
                }
            })

            router.push(
                `/callcenter/?tag=${tagId}&sort_by=-date_created&date_filter_start=${start_date}&date_filter_end=${end_date}&submit=Submit`
            )
        } else if (brandType == 'chat') {
            router.push({
                path: '/chat',
                query: {
                    path: `tag=${tagId}&sort_by=-chat__start_date&date_filter_start=${start_date}&date_filter_end=${end_date}&submit=Submit`
                }
            })
        } else {
            router.push({ path: '/pages/browse/list', query: { tag: tagId, submit: 'Submit' } })
        }
    } else {
        router.push({ path: '/pages/browse/list', query: { path: `tag=${tagId}&submit=Submit` } })
    }
    setTimeout(() => {
        router.go(0)
    }, 0)
}

function cancelTagEdit(): void {
    editTag.value = defaultEditTag;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const vFocus = {
    mounted: (el: HTMLElement) => el.focus()
}

</script>
