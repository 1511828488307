import { createRouter, createWebHistory, type Router } from 'vue-router'
// ignore-eslint
import VendorSignUp from './VendorSignUp/VendorSignUp.vue'
import VendorComponent from './VendorComponent.vue'

export const vendorViewRoutes = [
    {
        path: '/vendor',
        name: 'VendorView',
        component: VendorComponent
    },
    {
        path: '/vendor/signup/:caseId/:emailId',
        name: 'VendorSignup',
        component: VendorSignUp,
        meta: { requiresAuth: true }
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'VendorView') {
    router = createRouter({
        history: createWebHistory(),
        routes: vendorViewRoutes
    })
}