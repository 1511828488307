import { createRouter, createWebHistory, type Router } from 'vue-router'
// ignore-eslint
import NavBar from './NavBar.vue'

export const navBarViewRoutes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NavBar',
        component: NavBar,
        meta: { requiresAuth: true }
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'NavBarView') {
    router = createRouter({
        history: createWebHistory(),
        routes: navBarViewRoutes
    })
}