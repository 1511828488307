import { ref, watch, nextTick } from 'vue'

interface SizeMode {
    name: string
    code: string
}

interface PreferencesOptions {
    divId?: string
    global?: boolean
}

export function usePreferences(options: PreferencesOptions = { global: false }): any {
    const sizeModes = ref<SizeMode[]>([
        { name: 'Normal', code: 'normal' },
        { name: 'Compact', code: 'compact' }
    ])
    const sizeMode = ref<SizeMode>(JSON.parse(localStorage.getItem('sizeMode') || 'null') || sizeModes.value[0])

    const theme = ref(localStorage.getItem('theme') || 'light')

    const divId = ref<string | null>(options.divId || null)

    watch(
        theme,
        (newVal) => {
            nextTick(() => {
                if (options.global) {
                    document.documentElement.classList.toggle('dark', newVal === 'dark')
                } else if (divId.value) {
                    const div = document.getElementById(divId.value)
                    if (div) {
                        div.classList.toggle('dark', newVal === 'dark')
                    }
                }
                localStorage.setItem('theme', newVal)
            })
        },
        { immediate: true }
    )

    function setTheme(newTheme: 'dark' | 'light' | 'system'): void {
        if (newTheme === 'system') {
            localStorage.removeItem('theme')
            theme.value = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
        } else {
            console.log('setTheme', newTheme)
            theme.value = newTheme
        }
    }

    watch(
        sizeMode,
        (newVal: SizeMode) => {
            localStorage.setItem('sizeMode', JSON.stringify(newVal))
            if (options.global) {
                document.body.className = newVal.code
            } else if (divId.value) {
                const div = document.getElementById(divId.value)
                if (div) {
                    div.className = newVal.code
                }
            }
        },
        { immediate: true }
    )

    function toggleTheme(): void {
        setTheme(theme.value === 'dark' ? 'light' : 'dark')
    }

    return { sizeModes, sizeMode, theme, setTheme, toggleTheme }
}
