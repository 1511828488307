<template>
    <TableFilter
        :name="'channel'"
        :label="'Channel'"
        :is-active="isFilterActive"
        :enable-clear-filter="true"
        @clear-filter="onRemoveFilter"
    >
        <template #icon="{ variant }">
            <FaIcon icon="far fa-list" :class="variant"></FaIcon>
        </template>

        <template #value>
            <div
                v-if="isFilterActive"
                class="text-sm m-w-40 text-ellipsis overflow-hidden"
                data-testid="channel-filter-display"
            >
                {{ channelsInLabel }}
            </div>
        </template>

        <template #content>
            <div :key="componentKey" class="grid gap-2 my-4 w-full">
                <div
                    v-for="channel in channelOptions"
                    :key="channel.name"
                    class="flex align-center w-full text-sm py-1 px-4"
                >
                    <Checkbox
                        v-model="selectedChannels"
                        :input-id="channel.name"
                        :name="channel.name"
                        :value="channel.name"
                        :pt="{
                            root: { class: ['m-auto !h-4 !w-4 !py-0 '] },
                            box: { class: ['!h-4 !w-4 !rounded'] }
                        }"
                    />
                    <label :for="channel.name" class="flex w-full hover:cursor-pointer">
                        <span class="ml-4">{{ capitalizeFirstLetter(channel.name) }}</span>
                    </label>
                </div>
            </div>
        </template>
    </TableFilter>
</template>

<script lang="ts" setup>
import { computed, ref, watch, type PropType } from 'vue'
import TableFilter from '@/components/dataTable/tableFilter/TableFilter.vue'
import { capitalizeFirstLetter } from '@/utils/helpers'
import { HelpCenterChannel } from '@/interfaces/HelpCenter.types'

export interface ChannelOption {
    name: HelpCenterChannel
    isSelected: boolean
}

const props = defineProps({
    channels: {
        type: Array as PropType<HelpCenterChannel[]>,
        required: false,
        default: undefined
    }
})

const emit = defineEmits(['update-channel-filter', 'remove-channel-filter'])

const channelOptions = ref<ChannelOption[]>([
    { name: HelpCenterChannel.Call, isSelected: false },
    { name: HelpCenterChannel.Document, isSelected: false },
    { name: HelpCenterChannel.Email, isSelected: false },
    { name: HelpCenterChannel.Message, isSelected: false },
    { name: HelpCenterChannel.Social, isSelected: false },
    { name: HelpCenterChannel.Web, isSelected: false }
])

const selectedChannels = ref<string[]>([])

watch(
    () => props.channels,
    (payload: HelpCenterChannel[] | undefined) => {
        setTimeout(() => {
            if (payload) {
                const isDifferent =
                    payload.length !== selectedChannels.value.length ||
                    payload.some((channel, index) => channel !== selectedChannels.value[index])
                if (isDifferent) {
                    selectedChannels.value = payload
                }
            } else if (selectedChannels.value.length > 0) {
                selectedChannels.value = []
            }
        }, 0)
    }
)

const componentKey = ref(0)

watch(
    () => selectedChannels.value,
    (channels: string[]) => {
        componentKey.value += 1
        setTimeout(() => {
            emit('update-channel-filter', { channels: channels.length > 0 ? channels : undefined })
        }, 0)
    }
)

const onRemoveFilter = (): void => {
    emit('remove-channel-filter')
}

const isFilterActive = computed(() => {
    return selectedChannels.value.length > 0
})

const channelsInLabel = computed(() => {
    return selectedChannels.value.map((channel) => capitalizeFirstLetter(channel)).join(', ')
})
</script>
