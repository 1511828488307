import type { UserRoles } from '@/constants'
import type { BasePaginationParams } from './PageInfo.types'
import type { GetUserManagementApiParams } from '@/api/interfaces/UserManagement.api.types'

export enum UserManagementOrderByOptions {
    ActiveAsc = 'active',
    ActiveDesc = '-active',
    CreatedAtAsc = 'createdAt',
    CreatedAtDesc = '-createdAt',
    DeactivatedAtAsc = 'deactivatedAt',
    DeactivatedAtDesc = '-deactivatedAt',
    EmailAddressAsc = 'emailAddress',
    EmailAddressDesc = '-emailAddress',
    FirstNameAsc = 'firstName',
    FirstNameDesc = '-firstName',
    FullNameAsc = 'fullName',
    FullNameDesc = '-fullName',
    LastLoginAtAsc = 'lastLoginAt',
    LastLoginAtDesc = '-lastLoginAt',
    PrimaryRoleAsc = 'primaryRole',
    PrimaryRoleDesc = '-primaryRole',
    TypeAsc = 'type',
    TypeDesc = '-type',
    UsernameAsc = 'username',
    UsernameDesc = '-username'
}

export interface CompanyUser {
    active: boolean
    createdAt: string
    deactivatedAt: string
    emailAddress: string
    firstName: string
    fullName: string
    lastLoginAt: string
    lastName: string
    primaryRole: string
    username: string
}

export interface UserRoleInfo {
    primaryRole: UserRoles
    count: number | undefined
}

export interface UsersSummaryInfo {
    usersByRole: UserRoleInfo[]
}

interface GetUserManagementUIOverrides {
    role?: UserRoles[]
    active?: boolean
    inactive?: boolean
}

export type GetUserManagementUIParams = Omit<GetUserManagementApiParams, keyof GetUserManagementUIOverrides> &
    GetUserManagementUIOverrides &
    BasePaginationParams<UserManagementOrderByOptions>
