<template>
    <TableFilter
        :name="'user-status'"
        :label="'Status'"
        :is-active="isFilterActive"
        :enable-clear-filter="true"
        @clear-filter="clearUserStatusFilter"
    >
        <template #icon="{ variant }">
            <FaIcon icon="far fa-list" :class="variant"></FaIcon>
        </template>
        
        <template #value>
            <div v-if="isFilterActive" class="text-sm" data-testid="user-status-filter-display">
                {{ filterDisplayText }}
            </div>
        </template>

        <template #content>
            <div :key="componentKey" class="grid gap-2 my-4 w-full">
                <div class="flex align-center w-full text-sm py-1 px-4">
                    <Checkbox
                        v-model="activeInput"
                        input-id="active"
                        name="Active"
                        :binary="true"
                        :pt="{
                            root: { class: ['m-auto !h-4 !w-4 !py-0 '] },
                            box: { class: ['!h-4 !w-4 !rounded'] }
                        }"
                        @change="handleCheckbox"
                    />
                    <label for="active" class="flex w-full hover:cursor-pointer">
                        <span class="ml-4">Active</span>
                    </label>
                </div>
                <div class="flex align-center w-full text-sm py-1 px-4">
                    <Checkbox
                        v-model="inactiveInput"
                        input-id="inactive"
                        name="Inactive"
                        :binary="true"
                        :pt="{
                            root: { class: ['m-auto !h-4 !w-4 !py-0 '] },
                            box: { class: ['!h-4 !w-4 !rounded'] }
                        }"
                        @change="handleCheckbox"
                    />
                    <label for="inactive" class="flex w-full hover:cursor-pointer">
                        <span class="ml-4">Inactive</span>
                    </label>
                </div>
            </div>
        </template>
    </TableFilter>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import TableFilter from '@/components/dataTable/tableFilter/TableFilter.vue'
import Checkbox from 'primevue/checkbox'

const props = defineProps({
    active: {
        type: Boolean,
        required: false,
        default: false
    },
    inactive: {
        type: Boolean,
        required: false,
        default: false
    }
})

const emit = defineEmits(['update-user-active-filter', 'remove-user-active-filter'])

const activeInput = ref<boolean>()
const inactiveInput = ref<boolean>()
const componentKey = ref(0)

watch(
    () => [props.active, props.inactive],
    ([newActive, newInactive]) => {
        newActive === true ? (activeInput.value = true) : (activeInput.value = false)
        newInactive === true ? (inactiveInput.value = true) : (inactiveInput.value = false)
    },
    { immediate: true }
)

function handleCheckbox(): void {
    componentKey.value += 1
    if (activeInput.value || inactiveInput.value) {
        emit('update-user-active-filter', {
            active: activeInput.value,
            inactive: inactiveInput.value
        })
    } else {
        emit('remove-user-active-filter')
    }
}

function clearUserStatusFilter(): void {
    componentKey.value += 1
    emit('remove-user-active-filter')
}

const isFilterActive = computed(() => {
    return activeInput.value || inactiveInput.value ? true : false
})

const filterDisplayText = computed(() => {
    if (activeInput.value && inactiveInput.value) {
        return 'Active, Inactive'
    } else if (activeInput.value) {
        return 'Active'
    } else if (inactiveInput.value) {
        return 'Inactive'
    }
    return ''
})
</script>
