// Utility to add tw-preflight class to PrimeVue mask and dialog elements

export function observePrimeVueMasks(): MutationObserver {
    const selectors = [
        '[data-pc-section="mask"]',
        '[role="dialog"]',
        '[role="alert"]',
        '[data-pc-name="toast"]',
        '[role="alertdialog"]'
    ]

    function hasParentWithTwPreflight(element: HTMLElement): boolean {
        let parent = element.parentElement
        while (parent) {
            if (parent.classList.contains('tw-preflight')) {
                return true
            }
            parent = parent.parentElement
        }
        return false
    }

    function wrapElementWithTwPreflight(element: HTMLElement): void {
        // Don't wrap if element already has a parent with tw-preflight
        if (hasParentWithTwPreflight(element)) {
            return
        }

        // Create wrapper
        const wrapper = document.createElement('div')
        wrapper.className = 'tw-preflight'
        
        // Insert wrapper before element in the DOM
        element.parentNode?.insertBefore(wrapper, element)
        
        // Move element inside wrapper
        wrapper.appendChild(element)
    }

    // Create a new observer
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    // Check if the added node matches any of our selectors
                    if (selectors.some(selector => node.matches(selector))) {
                        wrapElementWithTwPreflight(node)
                    }
                    
                    // Also check children for matching elements
                    selectors.forEach(selector => {
                        const elements = node.querySelectorAll(selector)
                        elements.forEach(element => {
                            if (element instanceof HTMLElement) {
                                wrapElementWithTwPreflight(element)
                            }
                        })
                    })
                }
            })
        })
    })

    // Start observing the document body
    observer.observe(document.body, {
        childList: true,
        subtree: true
    })

    return observer
} 