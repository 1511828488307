<template>
    <div class="py-6">
        <div class="flex items-center justify-between h-8">
            <h2 class="text-base font-medium">User management</h2>
            <Button
                label="Manage users"
                text
                size="small"
                icon="far fa-external-link"
                icon-pos="right"
                :disabled="!encodedLastActiveCompanyId"
                @click="navigateToUserManagement"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
// import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/useUserStore'
import { storeToRefs } from 'pinia'

// const router = useRouter()
const userStore = useUserStore()
const { encodedLastActiveCompanyId } = storeToRefs(userStore)

const navigateToUserManagement = (): void => {
    if (encodedLastActiveCompanyId.value) {
        window.location.href = `/company/${encodedLastActiveCompanyId.value}/user-management`
    }
}
</script>
