import type { GetTagSummariesApiParams } from '@/api/interfaces/Tags.api.types'
import type { BasePaginationParams } from './PageInfo.types'

export enum TagTableOrderByOptions {
    AvgScoreAsc = 'averageScore',
    AvgScoreDesc = '-averageScore',
    CountAsc = 'count',
    CountDesc = '-count',
    CreatedAtAsc = 'createdAt',
    CreatedAtDesc = '-createdAt',
    IdAsc = 'id',
    IdDesc = '-id',
    LastAssignedAtAsc = 'lastAssignedAt',
    LastAssignedAtDesc = '-lastAssignedAt',
    NameAsc = 'name',
    NameDesc = '-name',
    OtherBrandCountAsc = 'otherBrandCount',
    OtherBrandCountDesc = '-otherBrandCount',
    OtherCountAsc = 'otherCount',
    OtherCountDesc = '-otherCount'
}

export interface TagUser {
    username: string
    email: string
    profileUrl: string
    isRedacted?: boolean
}

export interface ContentTag {
    id: number
    name: string
    isActive: boolean
    createdAt: string
    createdBy?: TagUser
    lastAssignedAt?: string
}

export interface TagSummary {
    brandUrl: string
    tag: ContentTag
    averageScore: number
    count: number
    otherBrandCount: number
}

export type GetTagTableUIParams =
    GetTagSummariesApiParams &
    BasePaginationParams<TagTableOrderByOptions>

export interface PostTagTableUIPayload {
    name: string
}

export interface PutTagTableUIPayload {
    tagId: number
    name: string
}
