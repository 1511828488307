<template>
    <Dialog
        :visible="dialogVisible"
        modal
        dismissable-mask
        :pt="{ header: { class: ['bg-surface-100 dark:bg-surface-800 !py-2 px-3'] } }"
        class="w-full max-w-4xl min-w-2xl"
        @update:visible="handleVisibilityChange"
    >
        <template #header>
            <div class="flex justify-between items-center">
                <FaIcon :icon="['fas', 'video']" class="mr-2" />
                <h3 class="text-surface-800 text-xl font-semibold leading-7">Video details</h3>
                <Button icon="pi pi-times" severity="danger" text @click="handleClose" />
            </div>
        </template>

        <div v-if="loading" class="flex justify-center p-4">
            <i class="pi pi-spinner pi-spin"></i>
        </div>

        <div v-else-if="video" class="p-8">
            <div class="flex flex-col">
                <div class="flex items-center gap-2">
                    <FaIcon :icon="['fas', typeIcon]" :class="typeColor" size="sm" />
                    <span class="text-surface-600">{{ typeLabel }}</span>
                </div>
                <span class="text-surface-700 text-2xl font-bold leading-loose">
                    {{ video.title }}
                </span>
                <span class="text-surface-700">{{ video.description }}</span>

                <div class="flex flex-wrap gap-2 mt-2">
                    <ChannelPill v-if="appliedToAllChannels" :is-all-channels="true" channel="all" />
                    <ChannelPill v-for="channel in sortedChannels" v-else :key="channel" :channel="channel" />
                </div>
            </div>
            <div class="aspect-video w-full max-h-[540px] bg-black mt-8 rounded-xl">
                <iframe
                    v-if="iframeVideoUrl"
                    :src="iframeVideoUrl"
                    class="w-full h-full"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-end gap-2 pt-4 border-t border-surface-200 dark:border-surface-700">
                <Button
                    v-if="video?.url"
                    rounded
                    size="small"
                    :pt="{
                        root: {
                            class: [
                                'bg-primary-200 !text-primary-500 border-none focus:ring-0 hover:!bg-primary-300 focus:!bg-primary-300'
                            ]
                        }
                    }"
                    @click="copyLinkToClipboard"
                >
                    <FaIcon icon="fas fa-link-simple" class="text-xs mx-1"></FaIcon>
                    <span class="text-sm self-center px-1">Copy link</span>
                </Button>
                <Button label="Close" rounded size="small" @click="handleClose" />
            </div>
        </template>
    </Dialog>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { HelpCenterChannel, HelpCenterType, type HelpCenterVideo } from '@/interfaces/HelpCenter.types'
import { useToast } from 'primevue/usetoast'
import { typeOptions } from './heloCenter.constants'
import helpCenterApi from '@/api/helpCenter.api'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const video = ref<HelpCenterVideo | null>(null)
const loading = ref(true)
const dialogVisible = ref(true)

const handleVisibilityChange = (value: boolean): void => {
    if (!value) {
        handleClose()
    }
}

const typeLabel = computed(() => {
    return typeOptions.find((type) => type.value === video.value?.type)?.label
})

const typeIcon = computed(() => {
    const icon = typeOptions.find((type) => type.value === video.value?.type)?.icon
    return icon ?? 'fa-question'
})

const appliedToAllChannels = computed(() => {
    const allChannels = Object.values(HelpCenterChannel)
    return allChannels.every((channel) => video.value?.channels?.includes(channel))
})

const sortedChannels = computed(() => {
    return [...(video.value?.channels || [])].sort((a, b) => {
        return a.localeCompare(b)
    })
})

const typeColor = computed(() => {
    switch (video.value?.type) {
        case HelpCenterType.TrainingVideo:
            return 'text-[#6270FC]'
        case HelpCenterType.TuesdayTutorial:
            return 'text-[#00AAED]'
        case HelpCenterType.Event:
            return 'text-black'
        default:
            return 'text-gray-500'
    }
})

const handleClose = (): void => {
    router.push({
        name: 'VideoLibrary',
        query: route.query
    })
}

const iframeVideoUrl = computed(() => {
    return video.value?.url?.replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
})

const copyLinkToClipboard = (): void => {
    if (video.value?.url) {
        navigator.clipboard.writeText(window.location.href)
        toast.add({
            severity: 'success',
            summary: 'Copied',
            detail: `Link copied to clipboard.`,
            life: 3000
        })
    }
}

const loadVideo = async (): Promise<void> => {
    loading.value = true
    try {
        const videoId = route.params.videoId as string
        const response = await helpCenterApi.getVideo(videoId)
        video.value = response.data as HelpCenterVideo
        // video.value = {
        //     id: '2nGRwndjau86sWIKo9xOc4QDxbE',
        //     // url: 'https://vimeo.com/10679287',
        //     url: 'https://player.vimeo.com/video/10679287',
        //     title: 'Video 18',
        //     description: 'Description for video 18',
        //     type: HelpCenterType.Training,
        //     channels: [
        //         HelpCenterChannel.Documents,
        //         HelpCenterChannel.Email,
        //         HelpCenterChannel.Messaging,
        //         HelpCenterChannel.Social,
        //         HelpCenterChannel.Web
        //     ],
        //     features: [HelpCenterFeature.Campaigns],
        //     publishTime: '2024-09-22T15:01:06.529347Z',
        //     createdAt: '2025-01-03T14:02:48.458252-05:00',
        //     updatedAt: '2025-01-03T14:02:48.458252-05:00'
        // }
    } catch (error) {
        console.error('Failed to load video:', error)
    } finally {
        loading.value = false
    }
}

// Watch for route changes to reload video if needed
watch(
    () => route.params.videoId,
    (newId) => {
        if (newId) {
            loadVideo()
        }
    }
)

onMounted(() => {
    loadVideo()
})
</script>
