<template>
    <div>
        <h1>{{ instanceName }} Component</h1>
        <!-- Add your component content here -->
        <a href="/vendor/signup/123/abc" class="block text-sm py-2 hover:bg-gray-100">Vendor Signup</a>
    </div>
</template>

<script setup lang="ts">
const instanceName = 'Vendor'
</script>

<style scoped>
/* Add your component styles here */
</style>