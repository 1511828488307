<template>
    <div class="flex flex-col gap-6 justify-between w-full h-full bg-white rounded-lg">
        <div class="flex flex-col gap-6 pt-6">
            <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Contact email</label>
                <div class="relative">
                    <div class="min-h-[42px] p-2 border rounded-md flex flex-wrap gap-2 bg-white">
                        <Chip
                            v-for="(email, index) in emails"
                            :key="index"
                            :label="email"
                            removable
                            class="text-sm"
                            @remove="removeEmail(index)"
                        />
                        <InputText
                            v-model="newEmail"
                            type="text"
                            placeholder="Add email"
                            :pt="{
                                root: {
                                    class: [
                                        'outline-none flex-1 min-w-[100px] text-sm focus:outline-none focus:ring-0 border-none'
                                    ]
                                }
                            }"
                            @keydown.space.prevent="addEmail"
                            @keydown.enter.prevent="addEmail"
                            @keydown.tab="addEmail"
                        />
                    </div>
                </div>
            </div>

            <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Subject</label>
                <InputText v-model="subject" placeholder="Enter subject" class="w-full text-sm" />
            </div>

            <div>
                <label class="block text-sm font-medium text-gray-700 mb-1">Description</label>
                <QuillEditor
                    v-model:content="description"
                    class="editor-container"
                    content-type="html"
                    placeholder="Enter description"
                />
            </div>
        </div>
        <div class="flex justify-end gap-3">
            <Button label="Discard" rounded size="small" text @click="handleDiscard" />
            <Button
                label="Submit"
                rounded
                size="small"
                :loading="isSubmitting"
                :disabled="!isValid || isSubmitting"
                @click="handleSubmit"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useUserStore } from '@/stores/useUserStore'
import { storeToRefs } from 'pinia'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { supportTemplates } from './supportTemplates'

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'submit', data: { emails: string[]; subject: string; description: string }): void
}>()

const props = defineProps<{
    isSubmitting?: boolean
    templateType?: string | null
}>()

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const emails = ref<string[]>([])
const newEmail = ref('')
const subject = ref('')
const description = ref('')

watch(
    () => props.templateType,
    (type) => {
        if (type && type in supportTemplates) {
            const template = supportTemplates[type]
            subject.value = template.subject
            description.value = template.description
        }
    },
    { immediate: true }
)

watch(
    () => user.value?.email,
    (newEmail) => {
        if (newEmail && emails.value.length === 0) {
            emails.value = [newEmail]
        }
    },
    { immediate: true }
)

const isValid = computed(() => {
    return emails.value.length > 0 && subject.value.trim() !== '' && description.value.trim() !== ''
})

const addEmail = (): void => {
    const email = newEmail.value.trim()
    if (email && isValidEmail(email) && !emails.value.includes(email)) {
        emails.value.push(email)
        newEmail.value = ''
    }
}

const removeEmail = (index: number): void => {
    emails.value.splice(index, 1)
}

const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

const handleDiscard = (): void => {
    if (user.value?.email) {
        emails.value = [user.value.email]
    } else {
        emails.value = []
    }
    newEmail.value = ''
    subject.value = ''
    description.value = ''
    emit('close')
}

const handleSubmit = (): void => {
    if (isValid.value) {
        emit('submit', {
            emails: emails.value,
            subject: subject.value,
            description: description.value
        })
    }
}
</script>

<style lang="scss" scoped>
.p-inputtext {
    @apply text-sm font-normal leading-tight text-surface-700;
}

:deep(.editor-container) {
    @apply min-h-[100px] max-h-[400px];

    .ql-editor {
        @apply min-h-[100px] max-h-[400px];

        &.ql-blank::before {
            @apply text-surface-400 dark:text-surface-500 font-normal not-italic text-sm;
        }
    }
}
</style>
