import type { App } from 'vue'
import * as Sentry from '@sentry/vue'
import router from '../router'

const sentryDsn = import.meta.env.VITE_SENTRY_DSN

export const initSentry = (app: App): void => {
    if (import.meta.env.VITE_ENVIRONMENT === 'local') {
        console.log('Sentry initialization skipped in local environment')
        return
    }

    const subdomain = window.location.hostname.split('.')[0]
    Sentry.init({
        app,
        dsn: sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true })
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/.*\.performline\.(qa|com)/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
        environment: subdomain
    })
}

const SentryPlugin = {
    install(app: App): void {
        initSentry(app)
    }
}

export default SentryPlugin
