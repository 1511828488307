import { createRouter, createWebHistory, type Router } from 'vue-router'
// ignore-eslint
import UserManagementTable from './UserManagementTable.vue'

export const userManagementViewRoutes = [
    {
        path: '/company/:companyId/user-management',
        name: 'UserManagementTable',
        component: UserManagementTable,
        meta: { requiresAuth: true }
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'UserManagementView') {
    router = createRouter({
        history: createWebHistory(),
        routes: userManagementViewRoutes
    })
}
