import { defineStore } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import authApi from '@/api/auth.api'
import { HelpCenterChannel } from '@/interfaces/HelpCenter.types'
import { base64url } from '@/utils/helpers'
import { UserRoles } from '@/constants'
import type { GetAuthMasqueradeRolesApiResponse, GetAuthUserDataApiResponse, AuthMasqueradeRolesApiItem, PostAuthResetPasswordApiBody, GetAuthUserBrandsApiResponse, AuthUserBrandApiItem, AuthUserDataApiItem, GetAuthUserSessionApiResponse, AuthUserSessionApiItem, PostAuthLoginApiResponse } from '@/api/interfaces/Auth.api.types'
import type { PostAccountPasswordUIPayload, PutAccountUsernameUIPayload } from '@/interfaces/Account.types'
import type { AuthMasqueradeUser, AuthUserBrandData, AuthUserData, PostAuthPasswordResetUIPayload } from '@/interfaces/Auth.types'
import type { FeatureFlags } from '@/interfaces/Common.types'
import type { PostAccountPasswordApiBody, PostAccountPasswordApiResponse, PutAccountUserNameApiBody, PutAccountUserNameApiResponse } from '@/api/interfaces/Account.api.types'

export const useUserStore = defineStore('user', () => {
    const user = ref<AuthUserData | null>(null)
    const rootUser = ref<string | undefined>()
    const masqueradeRoles = ref<AuthMasqueradeUser[]>([])
    const userBrands = ref<AuthUserBrandData[]>([])
    const isAuthenticated = ref(false)
    const loading = ref(false)
    const route = useRoute()
    const currentMasqueradeUser = ref<string | undefined>()
    const featureFlags = ref<FeatureFlags>()

    const isSuperUser = computed(() => {
        return user.value?.activeRole === UserRoles.Superuser
    })

    const isVendor = computed(() => {
        return user.value?.activeRole === UserRoles.Vendor
    })

    const isClientAdmin = computed(() => {
        return user.value?.activeRole === UserRoles.ClientAdmin
    })

    const isStaff = computed(() => {
        return user.value?.activeRole === UserRoles.Staff
    })

    const isSubmitter = computed(() => {
        return user.value?.activeRole === UserRoles.Submitter
    })

    const isThirdParty = computed(() => {
        return user.value?.activeRole === UserRoles.Thirdparty
    })

    const encodedLastActiveCompanyId = computed(() => {
        return user.value?.lastActiveCompanyId ? base64url(user.value.lastActiveCompanyId) : undefined
    })

    onMounted(async () => {
        if (!user.value && route.meta.requiresAuth) {
            await fetchUser()
        }
        if (userBrands.value.length === 0) {
            await fetchUserBrands()
        }

        await getFeatureFlags()
    })

    const setUser = (userData: AuthUserData | null): void => {
        user.value = userData
        isAuthenticated.value = !!userData
    }

    const loginUser = async (username: string, password: string): Promise<PostAuthLoginApiResponse> => {
        loading.value = true
        try {
            const response = await authApi.login(username, password)
            if (response.success) {
                await fetchUser()
            }
            return response
        } catch (error) {
            console.error('Login error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const logoutUser = async (): Promise<void> => {
        loading.value = true
        try {
            await authApi.logout()
        } catch (error) {
            console.error('Logout error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const forgotPassword = async (email: string): Promise<void> => {
        loading.value = true
        try {
            await authApi.forgotPassword(email)
        } catch (error) {
            console.error('Password reset error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const fetchUser = async (): Promise<AuthUserDataApiItem> => {
        loading.value = true
        try {
            const response: GetAuthUserDataApiResponse = await authApi.fetchUserData()
            setUser(response.data)
            return response.data
        } catch (error) {
            console.error('Fetch user error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const resetPassword = async (params: PostAuthPasswordResetUIPayload): Promise<void> => {
        loading.value = true
        try {
            const apiBody: PostAuthResetPasswordApiBody = {
                ...params
            }
            await authApi.resetPassword(apiBody)
        } catch (error) {
            console.error('Password change error:', error)
            throw error
        } finally {
            loading.value = false
        }
    }

    const fetchUserBrands = async (): Promise<AuthUserBrandApiItem[]> => {
        if (user.value && user.value.lastActiveCompanyId) {
            try {
                const response: GetAuthUserBrandsApiResponse = await authApi.fetchUserBrandsData(user.value?.lastActiveCompanyId)
                userBrands.value = response.data
                return response.data
            } catch (error) {
                console.error('Fetch user brands error:', error)
                throw error
            }
        }
        throw new Error(`No user or active company id found`)
    }

    const handleMasqueradeInit = async (): Promise<void> => {
        try {
            await fetchRootUser()
            if (rootUser.value) {
                await fetchAvailableMasqueradeRoles(rootUser.value)
            }
        } catch (error) {
            console.error('Unable to initialize masquerade roles. Error: ', error)
        }
    }

    const fetchRootUser = async (): Promise<AuthUserSessionApiItem> => {
        try {
            const response: GetAuthUserSessionApiResponse = await authApi.fetchRootUserData()
            const data = response.Results
            if (data.original_username) {
                rootUser.value = data.original_username
            } else {
                rootUser.value = data.username
            }
            return data
        } catch (error) {
            console.error('Get root user error: ', error)
            throw error
        }
    }

    const fetchAvailableMasqueradeRoles = async (rootUser: string): Promise<AuthMasqueradeRolesApiItem> => {
        try {
            const response: GetAuthMasqueradeRolesApiResponse = await authApi.fetchMasqueradeRoleData(rootUser)
            const data = response.Results
            if (data.masquerade_users.length > 0) {
                masqueradeRoles.value = [...data.masquerade_users, { admin_user: rootUser, masquerade_user: rootUser }] // root user must be included in this list
                currentMasqueradeUser.value = data.current_user
            }
            return data;
        } catch (error) {
            console.error('Get masquerade roles error: ', error)
            throw error
        }
    }

    const setMasqueradeUser = async (username: string): Promise<void> => {
        try {
            await authApi.setMasqueradeUser(username)
            location.href = location.origin
        } catch (error) {
            console.error('Set masquerade user error: ', error)
        }
    }

    const getFeatureFlags = async (): Promise<FeatureFlags> => {
        try {
            const response = await authApi.getFeatureFlags()
            featureFlags.value = response
            return response
        } catch (error) {
            console.error('Get feature flags failed. Error : ', error)
            throw error
        }
    }

    const activeBrand = computed<AuthUserBrandData | undefined>(() => {
        return userBrands.value.find((brand: AuthUserBrandData) => brand.id === user.value?.lastActiveBrandId) || userBrands.value[0]
    })

    const activeChannel = computed<HelpCenterChannel | undefined>(() => {
        return activeBrand.value?.channel
    })

    watch(activeBrand, (newVal) => {
        if (!newVal) {
            if (userBrands.value.length > 0) {
                // if no match found, switch to the first available brand in the list of brands
                window.location.href = `/accounts/v1/me/activate-group/?group=${userBrands.value[0].id}&next=/pages/browse/list&errnext=/login`
            } else {
                window.location.href = '/login/'
            }
        }
    })

    const updateUserName = async (username: string, params: PutAccountUsernameUIPayload): Promise<void> => {
        try {
            const apiBody: PutAccountUserNameApiBody = {
                ...params
            }
            const response: PutAccountUserNameApiResponse = await authApi.updateUserName(username, apiBody)
            if (user.value) {
                user.value.firstName = params.firstName
                user.value.lastName = params.lastName
            }
            return response.data
        } catch (error) {
            console.error('Update user name error:', error)
            throw error
        }
    }

    const changePassword = async (params: PostAccountPasswordUIPayload): Promise<void> => {
        try {
            const apiBody: PostAccountPasswordApiBody = {
                current_password: params.currentPassword,
                new_password: params.newPassword
            }
            const response: PostAccountPasswordApiResponse = await authApi.changePassword(apiBody)
            return response.data
        } catch (error) {
            console.error('Change password error:', error)
            throw error
        }
    }

    return {
        user,
        rootUser,
        masqueradeRoles,
        userBrands,
        isAuthenticated,
        loading,
        activeBrand,
        activeChannel,
        loginUser,
        logoutUser,
        forgotPassword,
        fetchUser,
        fetchUserBrands,
        resetPassword,
        setMasqueradeUser,
        isSuperUser,
        getFeatureFlags,
        handleMasqueradeInit,
        featureFlags,
        currentMasqueradeUser,
        updateUserName,
        changePassword,
        encodedLastActiveCompanyId,
        isVendor,
        isClientAdmin,
        isStaff,
        isSubmitter,
        isThirdParty
    }
})
