// src/router/index.ts for the global SPA
import { createRouter, createWebHistory } from 'vue-router'
import { tagsViewRoutes } from '@/views/TagsView/router'
import { userManagementViewRoutes } from '@/views/UserManagementView/router'
import TagsApp from '@/views/TagsView/App.vue'
import UserManagementViewApp from '@/views/UserManagementView/App.vue'
import { loginViewRoutes } from '@/views/LoginView/router'
import LoginViewApp from '@/views/LoginView/App.vue'
import { helpCenterViewRoutes } from '@/views/HelpCenterView/router'
import HelpCenterViewApp from '@/views/HelpCenterView/App.vue'
import { navBarViewRoutes } from '@/views/NavBarView/router'
import NavBarViewApp from '@/views/NavBarView/App.vue'
import { accountViewRoutes } from '@/views/AccountView/router'
import AccountViewApp from '@/views/AccountView/App.vue'
import { aboutViewRoutes } from '@/views/AboutView/router'
import AboutViewApp from '@/views/AboutView/App.vue'
import { vendorViewRoutes } from '@/views/VendorView/router';
import VendorViewApp from '@/views/VendorView/App.vue';

const routes = [
    {
        path: '/',
        redirect: '/tags'
    },
    {
        path: '/tags',
        component: TagsApp,
        children: tagsViewRoutes,
        redirect: '/brand/2b8ff207-0ceb-43a4-854c-d3d1aa39123c/tags',
        meta: { requiresAuth: true }
    },
    {
        path: '/user-management',
        component: UserManagementViewApp,
        children: userManagementViewRoutes,
        redirect: '/company/cfc6b710-7811-4aa8-803b-93f644a126fb/user-management',
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        component: LoginViewApp,
        children: loginViewRoutes,
        meta: { requiresAuth: false }
    },
    {
        path: '/help-center',
        component: HelpCenterViewApp,
        children: helpCenterViewRoutes,
        redirect: '/company/z8a3EHgRSqiAO5P2RKEm-w/help-center',
        meta: { requiresAuth: true }
    },
    {
        path: '/:pathMatch(.*)*',
        component: NavBarViewApp,
        children: navBarViewRoutes
    },
    {
        path: '/account',
        component: AccountViewApp,
        children: accountViewRoutes,
        meta: { requiresAuth: true }
    },
    {
        path: '/about-page',
        component: AboutViewApp,
        children: aboutViewRoutes,
        meta: { requiresAuth: true }
    },
    {
        path: '/vendor',
        component: VendorViewApp,
        children: vendorViewRoutes
    },
]



const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router
