import httpClient from './httpClient'
import { handleApiError } from '@/utils/handleApiError'
import type { AxiosResponse, AxiosError } from 'axios'
import type { GetAuthUserDataApiResponse, GetAuthUserBrandsApiResponse, PostAuthForgotPasswordApiResponse, PostAuthMasqueradeUserApiResponse, PostAuthResetPasswordApiBody, PostAuthResetPasswordApiResponse, GetAuthMasqueradeRolesApiResponse, GetAuthUserSessionApiResponse, PostAuthLoginApiResponse } from './interfaces/Auth.api.types'
import type { PostAccountPasswordApiBody, PostAccountPasswordApiResponse, PutAccountUserNameApiBody, PutAccountUserNameApiResponse } from './interfaces/Account.api.types'
import type { GetFeatureFlagsApiResponse } from './interfaces/Common.api.types'


const login = async (username: string, password: string): Promise<PostAuthLoginApiResponse> => {
    try {
        const response: AxiosResponse<PostAuthLoginApiResponse> = await httpClient.post(
            '/auth/login',
            { username, password },
            {
                validateStatus: (status) => status >= 200 && status <= 303,
                withCredentials: true
            }
        )
        return { success: true, message: 'Login successful', data: response.data }
    } catch (error) {
        console.log('login error')
        throw handleApiError(error as AxiosError)
    }
}

const logout = async (): Promise<void> => {
    try {
        window.location.href = `${baseUrl}/accounts/v1/logout/?next=/login&errnext=/login`
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const forgotPassword = async (email: string): Promise<PostAuthForgotPasswordApiResponse> => {
    try {
        const response: AxiosResponse<PostAuthForgotPasswordApiResponse> = await httpClient.post('/auth/forgot-password', { username: email })
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const fetchUserData = async (): Promise<GetAuthUserDataApiResponse> => {
    try {
        const response: AxiosResponse<GetAuthUserDataApiResponse> = await httpClient.get<GetAuthUserDataApiResponse>('/users/me')
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const fetchUserBrandsData = async (companyId: string): Promise<GetAuthUserBrandsApiResponse> => {
    try {
        const response: AxiosResponse<GetAuthUserBrandsApiResponse> = await httpClient.get<GetAuthUserBrandsApiResponse>(
            `/users/me/brands?companyId=${companyId}`
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const resetPassword = async (body: PostAuthResetPasswordApiBody): Promise<PostAuthResetPasswordApiResponse> => {
    try {
        const response: AxiosResponse<PostAuthResetPasswordApiResponse> = await httpClient.post('/auth/reset-password', body)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const baseUrl = import.meta.env.VITE_ROOT_URL ?? `https://${window.location.hostname}`

const fetchRootUserData = async (): Promise<GetAuthUserSessionApiResponse> => {
    try {
        // if (mockApi) {
        //     return sessionMe.Results
        // }
        const response: AxiosResponse<GetAuthUserSessionApiResponse> = await httpClient.get(`${baseUrl}/accounts/v1/me/session/`)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const fetchMasqueradeRoleData = async (rootUser: string): Promise<GetAuthMasqueradeRolesApiResponse> => {
    try {
        // if (mockApi) {
        //     return masqueradeRoleData.Results
        // }
        const response: AxiosResponse<GetAuthMasqueradeRolesApiResponse> = await httpClient.get(
            `${baseUrl}/accounts/v1/masquerade/?q=admin_user/${rootUser}`
        )
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const setMasqueradeUser = async (username: string): Promise<PostAuthMasqueradeUserApiResponse> => {
    try {
        const response: AxiosResponse<PostAuthMasqueradeUserApiResponse> = await httpClient.post<PostAuthMasqueradeUserApiResponse>(`${baseUrl}/accounts/v1/masquerade/${username}/`)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const getFeatureFlags = async (): Promise<GetFeatureFlagsApiResponse> => {
    try {
        const response: AxiosResponse<GetFeatureFlagsApiResponse> = await httpClient.get<GetFeatureFlagsApiResponse>(`${baseUrl}/util/feature_flags`)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const updateUserName = async (username: string, data: PutAccountUserNameApiBody): Promise<PutAccountUserNameApiResponse> => {
    try {
        const response: AxiosResponse<PutAccountUserNameApiResponse> = await httpClient.put(`/users/${username}/name`, data)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const changePassword = async (body: PostAccountPasswordApiBody): Promise<PostAccountPasswordApiResponse> => {
    try {
        const response: AxiosResponse<PostAccountPasswordApiResponse> = await httpClient.post('/auth/change-password', body)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default {
    login,
    logout,
    forgotPassword,
    fetchUserData,
    fetchUserBrandsData,
    resetPassword,
    fetchRootUserData,
    fetchMasqueradeRoleData,
    setMasqueradeUser,
    getFeatureFlags,
    updateUserName,
    changePassword,
}
