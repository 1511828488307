import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import companyUserApi from '@/api/companyUser.api'
import {
    type CompanyUser,
    type UsersSummaryInfo,
    UserManagementOrderByOptions,
    type GetUserManagementUIParams,
} from '@/interfaces/UserManagement.types'
import type { PageInfo } from '@/interfaces/PageInfo.types'
import type { GetUserManagementApiParams, GetUserManagementUsersApiResp, GetUserManagementUsersSummaryApiResp } from '@/api/interfaces/UserManagement.api.types'
import { sortOrderHelpers } from '@/interfaces/Sorting.types'

const defaultRoles = ['client', 'clientadmin', 'submitter', 'vendor']

interface UserManagementState {
    users: CompanyUser[]
    usersSummary: UsersSummaryInfo | undefined
    pageInfo: PageInfo | null
    error: string | null
    loading: boolean
}

export const useUserManagementStore = defineStore('userManagement', () => {

    const state = ref<UserManagementState>({
        users: [],
        usersSummary: undefined,
        pageInfo: null,
        error: null,
        loading: false,
    })

    const userManagementFilters = ref<GetUserManagementUIParams>({
        page: 1,
        size: 25,
        orderBy: UserManagementOrderByOptions.LastLoginAtAsc,
        sortOrder: -1,
        first: 0
    })

    const currentPage = computed(() => userManagementFilters.value.page || 1)
    const currentFirst = computed(() => (currentPage.value - 1) * (userManagementFilters.value.size || 100))

    watch(currentPage, () => {
        userManagementFilters.value.first = currentFirst.value
    })

    const fetchUsers = async (companyId: string): Promise<CompanyUser[]> => {
        state.value.loading = true
        try {
            const apiParams = transformFiltersToApiParams(userManagementFilters.value)
            const response: GetUserManagementUsersApiResp = await companyUserApi.fetchUsers(companyId, apiParams)

            state.value.users = response.data as CompanyUser[]
            state.value.pageInfo = response.pageInfo || null
            return response.data
        } catch (error) {
            console.error(error)
            throw new Error('Failed to fetch users')
        } finally {
            state.value.loading = false
        }
    }

    function transformFiltersToApiParams(filters: GetUserManagementUIParams): GetUserManagementApiParams {
        const { orderBy, sortOrder, ...rest } = filters
        return {
            ...rest,
            orderBy: orderBy && sortOrder ? sortOrderHelpers.applyToOrderBy(orderBy, sortOrder) : orderBy,
            role: filters?.role?.join(',') || defaultRoles.join(','),
            active: filters?.active?.toString(),
            inactive: filters?.inactive?.toString(),
        }
    }

    const patchUser = async (username: string, payload: { active: boolean }): Promise<void> => {
        state.value.loading = true
        try {
            await companyUserApi.patchUser(username, payload)
            const index = state.value.users.findIndex((user) => user.username === username)
            if (index !== -1) {
                state.value.users[index].active = payload.active
            }
        } catch (error) {
            console.error(error)
            throw new Error(`Failed to update user with username ${username}`)
        } finally {
            state.value.loading = false
        }
    }

    // Passing filters as object here to prevent altering the currently applied table filters
    const fetchUsersSummary = async (companyId: string, filters: GetUserManagementUIParams): Promise<void> => {
        try {
            const apiParams = transformFiltersToApiParams(filters)
            const response: GetUserManagementUsersSummaryApiResp = await companyUserApi.fetchUsersSummary(companyId, apiParams)

            state.value.usersSummary = response.data
        } catch (error) {
            console.error(error)
            throw new Error('Failed to fetch users summary')
        }
    }

    function updateUserFilters(params: Partial<GetUserManagementUIParams>): void {
        userManagementFilters.value = {
            ...userManagementFilters.value,
            ...params
        }
    }

    function removeUserFilters<T extends keyof GetUserManagementUIParams>(keysToClear: T[]): void {
        keysToClear.forEach((key) => {
            if (key in userManagementFilters.value) {
                userManagementFilters.value[key] = undefined as GetUserManagementUIParams[T]
            }
        })
    }

    function clearAllUserFilters(): void {
        removeUserFilters(['active', 'inactive', 'createdAtBefore', 'createdAtAfter', 'deactivatedAtBefore', 'deactivatedAtAfter', 'lastLoginBefore', 'lastLoginAfter', 'role', 'search'])
    }

    function downloadFile(csvData: string, filename: string): void {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
    }

    async function exportUsers(companyId: string, filename: string = 'users_export'): Promise<void> {
        try {
            const apiParams = transformFiltersToApiParams(userManagementFilters.value)
            const csvData = await companyUserApi.exportUsers(companyId, apiParams)

            downloadFile(csvData, filename);
        } catch (err) {
            if (err instanceof Error && err.name === 'AbortError') {
                throw err; // Throw AbortError to be caught in the component
            }
            console.error('Export error:', err)
            throw new Error('Failed to export users')
        }
    }

    const searchValue = computed(() => {
        return userManagementFilters.value.search || ''
    })

    return {
        state,
        userManagementFilters,
        searchValue,
        currentPage,
        currentFirst,
        fetchUsers,
        patchUser,
        fetchUsersSummary,
        exportUsers,
        updateUserFilters,
        removeUserFilters,
        clearAllUserFilters,
    }
})
