import type { GetHelpCenterApiParams, PostHelpCenterApiBody, PutHelpCenterApiBody } from '@/api/interfaces/HelpCenter.api.types'
import type { BasePaginationParams } from './PageInfo.types'

export enum HelpCenterChannel {
    Call = 'call',
    Document = 'document',
    Email = 'email',
    Message = 'message',
    Social = 'social',
    Web = 'web'
}

export enum HelpCenterFeature {
    Assets = 'assets',
    Campaigns = 'campaigns',
    Remediation = 'remediation',
    Reporting = 'reporting',
    Rules = 'rules',
    TrafficSources = 'trafficSources',
    UserManagement = 'userManagement',
    VendorPortal = 'vendorPortal',
    Other = 'other'
}

export enum HelpCenterType {
    Event = 'event',
    TuesdayTutorial = 'tuesdayTutorial',
    TrainingVideo = 'training'
}

export enum HelpCenterOrderByOptions {
    PublishTimeAsc = 'publishTime',
    PublishTimeDesc = '-publishTime',
    TitleAsc = 'title',
    TitleDesc = '-title',
    TypeAsc = 'type',
    TypeDesc = '-type'
}

export interface HelpCenterVideo {
    id: string
    title: string
    description: string
    url: string
    type: HelpCenterType
    channels: HelpCenterChannel[]
    features?: HelpCenterFeature[]
    publishTime: string
    createdAt: string
    updatedAt: string
    isDeleted?: string | null
}


interface HelpCenterUIOverrides {
    channels: HelpCenterChannel[]
    features: HelpCenterFeature[]
    type: HelpCenterType
}

interface GetHelpCenterUIOverrides {
    includeChannels?: HelpCenterChannel[]
    includeFeatures?: HelpCenterFeature[]
    includeTypes?: HelpCenterType[]
}

export type GetHelpCenterUIParams = Omit<GetHelpCenterApiParams, keyof GetHelpCenterUIOverrides> &
    GetHelpCenterUIOverrides &
    BasePaginationParams<HelpCenterOrderByOptions>

export type PostHelpCenterUIPayload = Omit<PostHelpCenterApiBody, keyof HelpCenterUIOverrides> & HelpCenterUIOverrides

export type PutHelpCenterUIPayload = Omit<PutHelpCenterApiBody, keyof HelpCenterUIOverrides> & HelpCenterUIOverrides