<template>
    <div class="bg-white p-8 m-8 rounded-lg shadow-md w-96">
        <div class="flex justify-center mb-6">
            <img src="@/assets/images/performline-logo-blue.png" alt="Performline" class="h-12" />
        </div>

        <h1 class="text-xl font-semibold text-center mb-4">Change Your Password</h1>

        <div v-if="changeError" class="bg-red-500/20 text-red-600 text-left mb-4 px-4 py-2 text-sm">
            {{ changeError }}
        </div>

        <div v-if="showSuccessMessage" class="mt-4">
            <p class="font-semibold">Success! Your password has been successfully changed.</p>
            <br />
            <Button
                rounded
                type="submit"
                label="Login"
                class="w-full bg-blue-500 text-white py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 mb-3"
                :disabled="isSubmitting"
                @click="goToLogin"
            >
            </Button>
        </div>

        <form v-else class="w-full" @submit.prevent="handleSubmit">
            <div class="mb-4">
                <label for="newPassword" class="block text-gray-600 mb-1 text-sm font-semibold">NEW PASSWORD*</label>
                <input
                    id="newPassword"
                    v-model="newPassword"
                    type="password"
                    required
                    class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    @blur="validatePassword"
                />
                <p class="text-xs mt-1" :class="{ 'text-red-500': passwordError, 'text-gray-500': !passwordError }">
                    Password must include at least 8 characters, including 1 lowercase letter, 1 uppercase letter, and 1
                    number. Additionally, you must wait one year to reuse a password.
                </p>
            </div>

            <div class="mb-6">
                <label for="confirmPassword" class="block text-gray-600 mb-1 text-sm font-semibold"
                    >NEW PASSWORD (AGAIN)*</label
                >
                <input
                    id="confirmPassword"
                    v-model="confirmPassword"
                    type="password"
                    required
                    class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                    @blur="validateConfirmPassword"
                />
                <span v-if="confirmPasswordError" class="text-red-500 text-sm">{{ confirmPasswordError }}</span>
            </div>

            <Button
                rounded
                type="submit"
                class="w-full bg-blue-500 text-white py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                :disabled="isSubmitting || !canSubmit"
            >
                {{ isSubmitting ? 'Changing...' : 'Change my password' }}
            </Button>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/useUserStore'
import { useRouter } from 'vue-router'
import type { PostAuthPasswordResetUIPayload } from '@/interfaces/Auth.types'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()

const newPassword = ref('')
const confirmPassword = ref('')
const passwordError = ref('')
const confirmPasswordError = ref('')
const showSuccessMessage = ref(false)
const changeError = ref('')
const isSubmitting = ref(false)

const validatePassword = (): void => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
    passwordError.value = regex.test(newPassword.value)
        ? ''
        : 'Password must include at least 8 characters, including 1 lowercase letter, 1 uppercase letter, and 1 number.'
}

const validateConfirmPassword = (): void => {
    confirmPasswordError.value = newPassword.value === confirmPassword.value ? '' : 'Passwords do not match.'
}

const canSubmit = computed(() => {
    return (
        !passwordError.value && !confirmPasswordError.value && newPassword.value !== '' && confirmPassword.value !== ''
    )
})

const goToLogin = (): void => {
    router.push('/login')
}

const handleSubmit = async (): Promise<void> => {
    validatePassword()
    validateConfirmPassword()

    if (passwordError.value || confirmPasswordError.value) return

    isSubmitting.value = true
    changeError.value = ''
    showSuccessMessage.value = false

    try {
        const token = route.query.t as string
        const params: PostAuthPasswordResetUIPayload = { resetToken: token, newPassword: newPassword.value }
        await userStore.resetPassword(params)
        showSuccessMessage.value = true
    } catch (error: any) {
        changeError.value = error.message
    } finally {
        isSubmitting.value = false
    }
}
</script>
