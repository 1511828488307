import { defineStore } from 'pinia'
import { ref } from 'vue'
import tagsApi from '@/api/tags.api'
import {
    type TagSummary,
    type GetTagTableUIParams,
    type PostTagTableUIPayload,
    type PutTagTableUIPayload,
} from '@/interfaces/Tag.types'
import type { PageInfo } from '@/interfaces/PageInfo.types'
import type { FilterAverageScorePayload } from '@/interfaces/Table.types'
import type { ContentTagApiItem, GetContentTagSummariesApiResponse, GetTagSummariesApiParams, PostContentTagApiBody, PutContentTagApiBody, PutContentTagApiResponse } from '@/api/interfaces/Tags.api.types'
import { sortOrderHelpers } from '@/interfaces/Sorting.types'

export const useTagStore = defineStore('tags', () => {
    const tagSummaries = ref<TagSummary[]>([])
    const pageInfo = ref<PageInfo | null>({ totalCount: 0, hasMore: false, currentPage: 1, size: 25, totalPages: 1 })
    const loading = ref<boolean>(true)
    const avgScoreFilter = ref<FilterAverageScorePayload>({ minScore: null, maxScore: null, type: 'avgScore', isCustom: false })

    async function removeTag(brandId: string, tagId: number): Promise<void> {
        loading.value = true

        try {
            await tagsApi.deleteTag(brandId, tagId)
            tagSummaries.value = tagSummaries.value.filter((tagSummary) => tagSummary.tag.id !== tagId)
        } catch (err: unknown) {
            console.error(err)
            throw new Error(`Failed to delete tag with id ${tagId}`)
        } finally {
            loading.value = false
        }
    }

    async function editTag(brandId: string, params: PutTagTableUIPayload): Promise<ContentTagApiItem> {
        loading.value = true

        const index = tagSummaries.value.findIndex((tagSummary) => tagSummary.tag.id === params.tagId)
        if (index !== -1) {
            const apiBody: PutContentTagApiBody = {
                name: params.name
            }
            try {
                const response: PutContentTagApiResponse = await tagsApi.updateTag(brandId, params.tagId, apiBody)
                tagSummaries.value[index].tag = response.data
                return response.data
            } catch (error) {
                console.error(error)
                throw new Error(`Failed to update tag with id ${params.tagId}`)
            } finally {
                loading.value = false
            }
        }

        loading.value = false
        throw new Error(`Tag with id ${params.tagId} not found in the current tag list`)
    }

    const fetchTags = async (brandId: string, params: GetTagTableUIParams): Promise<GetContentTagSummariesApiResponse> => {
        loading.value = true

        const apiParams: GetTagSummariesApiParams = transformUIParamsToApiParams(params)
        try {
            const response: GetContentTagSummariesApiResponse = await tagsApi.getTags(brandId, apiParams)
            tagSummaries.value = response.data
            pageInfo.value = response.pageInfo || null
            return response
        } catch (error) {
            console.error(error)
            throw new Error('Failed to fetch tags')
        } finally {
            loading.value = false
        }
    }

    function transformUIParamsToApiParams(params: GetTagTableUIParams): GetTagSummariesApiParams {
        const { orderBy, sortOrder, ...rest } = params

        return {
            ...rest,
            orderBy: orderBy && sortOrder ? sortOrderHelpers.applyToOrderBy(orderBy, sortOrder) : orderBy,
        }
    }

    const createTag = async (brandId: string, params: PostTagTableUIPayload): Promise<ContentTagApiItem> => {
        loading.value = true

        const apiBody: PostContentTagApiBody = {
            name: params.name
        }

        try {
            const resp = await tagsApi.createTag(brandId, apiBody)
            return resp.data
        } catch (error: any) {
            console.error(error)
            throw new Error(error.message)
        } finally {
            loading.value = false
        }
    }

    const clearScoreFilter = (): void => {
        avgScoreFilter.value = { minScore: null, maxScore: null, type: 'avgScore', isCustom: avgScoreFilter.value.isCustom }
    }

    const clearAllFilters = (): void => {
        avgScoreFilter.value = { minScore: null, maxScore: null, type: 'avgScore', isCustom: avgScoreFilter.value.isCustom }
    }

    return { avgScoreFilter, loading, tagSummaries, removeTag, editTag, fetchTags, pageInfo, createTag, clearScoreFilter, clearAllFilters }
})
