import { createRouter, createWebHistory, type Router } from 'vue-router'
import TagsTable from './TagsTable.vue'

export const tagsViewRoutes = [
    {
        path: '/brand/:brandId/tags',
        name: 'TagTable',
        component: TagsTable,
        meta: { requiresAuth: true }
    }
]

// Conditionally create and export the router only if running standalone
export let router: Router | undefined
if (import.meta.env.VITE_INSTANCE_NAME === 'TagsView') {
    router = createRouter({
        history: createWebHistory(),
        routes: tagsViewRoutes
    })
}
