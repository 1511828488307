<template>
    <TableHeader
        :title="'Tags'"
        :has-selected-rows="isTagSelected"
        :has-active-filters="hasActiveFilters"
        full-page
        @clear-all-filters="emit('clear-all-filters')"
    >
        <template v-if="isTagSelected" #selectedRowActions>
            <Button
                data-testid="selected-tags"
                outlined
                class="text-surface-700 text-sm border border-surface-950/10 rounded pt-1 pb-1 pl-3 pr-3 flex items-center focus:ring focus:ring-offset-2 focus:ring-primary-500"
                @click="emit('deselectAllTags')"
            >
                <span class="mr-2">{{ selectedTags.length }} selected</span>
                <FaIcon icon="far fa-xmark" class="text-xs ml-2"></FaIcon>
            </Button>
        </template>

        <template v-else #filterSection>
            <!-- Add 'applyScoreFilter' to control when filtering occurs -->
            <FilterAverageScore
                :avg-score-filter-payload="avgScoreFilterPayload"
                @update-avg-score-filter="emit('update-avg-score-filter', $event)"
                @remove-filter="emit('remove-filter', $event)"
            ></FilterAverageScore>
        </template>

        <template #right>
            <Button
                v-if="isTagSelected"
                data-testid="delete-selected-tags"
                text
                class="text-red-600"
                @click="handleDeleteSelectedTags"
            >
                <FaIcon icon="far fa-trash-can"></FaIcon>
                <span class="ml-2">Delete</span>
            </Button>
            <div v-else class="flex items-center gap-4 relative w-full">
                <IconField class="flex-grow">
                    <InputIcon class="far fa-search" />
                    <InputText
                        v-model="searchQuery"
                        size="small"
                        placeholder="Search tag name"
                        class="pl-10 pr-10 py-2 border rounded-lg w-full"
                    />
                    <!-- Clear 'X' button for search -->
                    <button
                        v-if="searchQuery.length > 0"
                        class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                        @click="clearSearch"
                    >
                        <FaIcon icon="far fa-xmark" />
                    </button>
                </IconField>
                <AddTag @tag-created="emit('tag-created')" />
            </div>
        </template>
    </TableHeader>
</template>

<script setup lang="ts">
import { ref, watch, computed, type PropType } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import AddTag from './AddTag.vue'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import type { TagSummary } from '@/interfaces/Tag.types'
import type { FilterAverageScorePayload } from '@/interfaces/Table.types'
import TableHeader from '@/components/dataTable/TableHeader.vue'
import FilterAverageScore from '@/components/dataTable/tableFilter/filters/FilterAverageScore.vue'
import { useTagStore } from '@/stores/useTagStore'

const props = defineProps({
    selectedTags: {
        type: Array as PropType<TagSummary[]>,
        required: false,
        default: () => []
    },
    brandId: {
        type: String,
        required: true
    },
    avgScoreFilterPayload: {
        type: Object as PropType<FilterAverageScorePayload>,
        required: true
    },
    searchString: {
        type: String,
        required: false,
        default: ''
    }
})

const tagsStore = useTagStore()

const emit = defineEmits([
    'search',
    'deselectAllTags',
    'tags-deleted-success',
    'tag-created',
    'update-avg-score-filter',
    'remove-filter',
    'clear-all-filters'
])

const confirm = useConfirm()
const toast = useToast()

const isTagSelected = computed(() => {
    return props.selectedTags && props.selectedTags.length > 0
})
// add this to the query params
const searchQuery = ref('')

function clearSearch(): void {
    searchQuery.value = ''
    emit('search', searchQuery.value)
}

const debouncedSearch = useDebounceFn(
    () => {
        emit('search', searchQuery.value)
    },
    400,
    { maxWait: 3000 }
)
watch(searchQuery, () => {
    debouncedSearch()
})

watch(
    () => props.searchString,
    (payload) => {
        if (payload && typeof payload == 'string') searchQuery.value = payload
    }
)

async function handleDeleteSelectedTags(): Promise<void> {
    confirm.require({
        group: 'confirm-tags-delete',
        header: `Delete ${props.selectedTags.length} items?`,
        message: 'This action cannot be undone.',
        rejectProps: {
            label: 'Cancel',
            rounded: true,
            text: true,
            size: 'small'
        },
        acceptProps: {
            label: 'Delete',
            severity: 'danger',
            rounded: true,
            size: 'small'
        },
        accept: async () => {
            try {
                const deletePromises: Promise<any>[] = []
                props.selectedTags.forEach((tagSummary: TagSummary) => {
                    deletePromises.push(tagsStore.removeTag(props.brandId, tagSummary.tag.id))
                })
                await Promise.all(deletePromises)
                toast.add({
                    severity: 'success',
                    summary: 'Confirmed',
                    detail: `${props.selectedTags.length} ${props.selectedTags.length > 1 ? 'tags' : 'tag'} successfully deleted.`,
                    life: 3000
                })
                emit('tags-deleted-success')
            } catch (err: any) {
                console.error(err.message)
                toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: `Error trying to delete tags.`,
                    life: 3000
                })
            }
        },
        reject: () => {
            toast.add({ severity: 'info', summary: 'Delete Canceled', life: 3000 })
        }
    })
}

const hasActiveFilters = computed(() => {
    return props.avgScoreFilterPayload.maxScore !== null || props.avgScoreFilterPayload.minScore !== null
})
</script>
