<template>
    <div class="py-14 px-12 max-w-[1980px] mx-auto">
        <div class="flex justify-between items-center mb-4">
            <div>
                <h2 class="text-2xl font-semibold">{{ title }}</h2>
                <p>{{ subtitle }}</p>
            </div>
            <Button label="View all" text class="text-sm font-medium" @click="navigateToLibrary" />
        </div>

        <!-- Loading State -->
        <div v-if="isLoading" class="grid grid-cols-4 gap-4">
            <VideoLibrarySkeleton :number-of-cards="4" />
        </div>

        <!-- Empty State -->
        <div v-else-if="!videos.length" class="text-center py-8">
            <p class="text-lg text-surface-600">No videos available at the moment.</p>
        </div>

        <!-- Carousel Content -->
        <div v-else class="relative">
            <Button
                v-tooltip.right="'Previous'"
                rounded
                size="small"
                class="carousel-nav-button -translate-x-1/2"
                :disabled="atStart"
                @click="scroll(-1)"
            >
                <FaIcon icon="fas fa-arrow-left" :class="{ 'text-surface-950': atStart }" />
            </Button>

            <div
                ref="trackRef"
                class="carousel-track"
                @mousedown="startDrag"
                @touchstart="startDrag"
                @mousemove="onDrag"
                @touchmove="onDrag"
                @mouseup="stopDrag"
                @touchend="stopDrag"
                @mouseleave="stopDrag"
            >
                <div class="flex gap-4 w-fit my-1">
                    <div v-for="video in videos" :key="video.id" class="flex-none w-[300px]">
                        <VideoCard :video="video" :can-edit="isSuperUser" @edit="helpCenterStore.openVideoFormDialog" />
                    </div>
                </div>
            </div>

            <Button
                v-tooltip.left="'Next'"
                icon="fas fa-arrow-right"
                rounded
                size="small"
                class="carousel-nav-button translate-x-1/2 right-0"
                :disabled="atEnd"
                @click="scroll(1)"
            >
                <FaIcon icon="fas fa-arrow-right" :class="{ 'text-surface-950': atEnd }" />
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import type { GetHelpCenterUIParams, HelpCenterVideo } from '@/interfaces/HelpCenter.types'
import { useUserStore } from '@/stores/useUserStore'
import { useHelpCenterStore } from '@/stores/useHelpCenterStore'
import { storeToRefs } from 'pinia'
import { HelpCenterOrderByOptions } from '@/interfaces/HelpCenter.types'
import VideoCard from './VideoCard.vue'
import VideoLibrarySkeleton from './videoLibrary/VideoLibrarySkeleton.vue'

const props = defineProps<{
    title: string
    subtitle: string
    filters: Partial<GetHelpCenterUIParams>
}>()

const router = useRouter()
const trackRef = ref<HTMLElement | null>(null)
const atStart = ref(true)
const atEnd = ref(false)
const isDragging = ref(false)
const startX = ref(0)
const scrollLeft = ref(0)
const videos = ref<HelpCenterVideo[]>([])
const isLoading = ref(false)

const userStore = useUserStore()
const helpCenterStore = useHelpCenterStore()
const { isSuperUser } = storeToRefs(userStore)

let animationFrame: number | null = null

// Smooth scroll with easing
const smoothScroll = (targetScroll: number): void => {
    if (!trackRef.value) return
    if (animationFrame) cancelAnimationFrame(animationFrame)

    const startScroll = trackRef.value.scrollLeft
    const distance = targetScroll - startScroll
    const startTime = performance.now()
    const duration = 300

    const animate = (currentTime: number): void => {
        const progress = Math.min((currentTime - startTime) / duration, 1)
        if (trackRef.value) {
            trackRef.value.scrollLeft = startScroll + (distance * (1 - Math.pow(1 - progress, 3)))
            if (progress < 1) animationFrame = requestAnimationFrame(animate)
        }
    }
    animationFrame = requestAnimationFrame(animate)
}

// Navigation
const scroll = (direction: number): void => {
    if (!trackRef.value) return
    smoothScroll(trackRef.value.scrollLeft + (direction * 316))
}

const checkNavigation = (): void => {
    if (!trackRef.value) return
    atStart.value = trackRef.value.scrollLeft <= 0
    atEnd.value = trackRef.value.scrollLeft + trackRef.value.clientWidth >= trackRef.value.scrollWidth - 10
}

// Drag handlers
const startDrag = (e: MouseEvent | TouchEvent): void => {
    isDragging.value = true
    startX.value = 'touches' in e ? e.touches[0].pageX : e.pageX
    scrollLeft.value = trackRef.value?.scrollLeft || 0
    document.body.style.userSelect = 'none'
}

const onDrag = (e: MouseEvent | TouchEvent): void => {
    if (!isDragging.value || !trackRef.value) return
    e.preventDefault()
    const x = 'touches' in e ? (e as TouchEvent).touches[0].pageX : (e as MouseEvent).pageX
    const walk = (startX.value - x) * 1.5
    trackRef.value.scrollLeft = scrollLeft.value + walk
}

const stopDrag = (): void => {
    isDragging.value = false
    document.body.style.userSelect = ''
}

// Data fetching
const fetchCarouselVideos = async (): Promise<void> => {
    isLoading.value = true
    try {
        videos.value = await helpCenterStore.fetchVideos({
            storeResults: false,
            filters: {
                ...props.filters,
                size: 8,
                orderBy: HelpCenterOrderByOptions.PublishTimeAsc,
                sortOrder: -1
            }
        }) || []
    } catch (error) {
        console.error('Error fetching carousel videos:', error)
    } finally {
        isLoading.value = false
    }
}

const navigateToLibrary = (): void => {
    router.push({
        name: 'VideoLibrary',
        query: { type: props.filters.includeTypes?.[0] }
    })
}

// Lifecycle
onMounted(async (): Promise<void> => {
    await fetchCarouselVideos()
    trackRef.value?.addEventListener('scroll', checkNavigation)
    window.addEventListener('resize', checkNavigation)
    checkNavigation()
})

onBeforeUnmount((): void => {
    if (animationFrame) cancelAnimationFrame(animationFrame)
    trackRef.value?.removeEventListener('scroll', checkNavigation)
    window.removeEventListener('resize', checkNavigation)
})
</script>

<style scoped>
.carousel-nav-button {
    @apply absolute z-10 top-1/2 -translate-y-1/2 !w-10 !h-10 bg-primary-500 opacity-60 hover:opacity-100 disabled:opacity-40 disabled:cursor-not-allowed disabled:bg-[#030b12]/10 border-none;
}

.carousel-track {
    @apply overflow-x-auto relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: auto;
    cursor: grab;
    touch-action: pan-x pinch-zoom;
}

.carousel-track:active {
    cursor: grabbing;
}

.carousel-track::-webkit-scrollbar {
    display: none;
}
</style>
