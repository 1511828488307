<template>
    <Button
        id="user-nav-button"
        type="button"
        icon="fas fa-circle-user"
        aria-haspopup="true"
    data-testid="navbar-menu-button"
        aria-controls="overlay_menu"
        :label="user?.firstName || user?.username"
        class="bg-transparent border-none !text-[rgba(55,58,60,1)] hover:text-[rgb(13,92,171)] whitespace-nowrap"
        @click="toggleMenu"
    />
    <Menu
        id="navbar_overlay_menu"
        ref="menu"
        :model="menuItems"
        :popup="true"
        class="border-[rgb(175,175,175)] text-sm"
        :pt="{
            itemLink: { class: ['!py-2'] }
        }"
    >
        <template #item="{ item, props }">
            <a v-if="item.link" v-ripple class="flex items-center" v-bind="props.action" :href="item.link">
                <FaIcon :icon="item.icon" class="h-4 w-4 navbar-menu-icon"></FaIcon>
                <span class="ml-2">{{ item.label }}</span>
            </a>
            <a v-else v-ripple class="flex items-center" v-bind="props.action" @click="item.handleClick($event)">
                <FaIcon :icon="item.icon" class="h-4 w-4 navbar-menu-icon"></FaIcon>
                <span class="ml-2">{{ item.label }}</span>
            </a>
        </template>
    </Menu>
    <Popover
        id="masquerade_overlay_menu"
        ref="masqueradePopover"
        :pt="{
            root: {
                class: [
                    'max-h-[66vh] overflow-x-auto mt-1 rounded border-black-100[.1] drop-shadow text-surface-700 !p-0 bg-white'
                ]
            }
        }"
    >
        <ul class="list-none">
            <li
                v-for="role in masqueradeRoles"
                :key="role.masquerade_user"
                v-ripple
                v-tooltip.hover.top="{ value: role.masquerade_user, showDelay: 1500 }"
                class="!hover:background-surface-950[.06] active:bg-brand-500/[.12] hover:cursor-pointer !text-[13px] !px-2 !py-1.5"
                :class="{
                    'bg-brand-500/[.12] !text-brand-500 pointer-events-none': isMasqUser(role.masquerade_user)
                }"
                @click="updateMasqueradeUser(role.masquerade_user)"
            >
                {{ role.masquerade_user }}
            </li>
        </ul>
    </Popover>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/useUserStore'
import { storeToRefs } from 'pinia'
import type { MenuItem } from 'primevue/menuitem'
import { computed, ref } from 'vue'

export interface NavMenuItem {
    id: string
    label: string
    icon?: string
    isVisible: boolean
    link?: string
    handleClick?: (event: Event) => void
}

const userStore = useUserStore()
const { user, encodedLastActiveCompanyId, masqueradeRoles, currentMasqueradeUser, featureFlags, isThirdParty, isSuperUser, isStaff } =
    storeToRefs(userStore)

const masqueradePopover = ref()

const helpCenterLink = computed(() => {
    return featureFlags.value?.ux_help_center ? `/company/${encodedLastActiveCompanyId.value}/help-center` : '/about/support/'
})

const accountLink = computed(() => {
    return featureFlags.value?.ux_account_page ? `/account` : '/account/account/'
})

const menu = ref()
const menuItems = computed<MenuItem[]>(() => {
    const items: MenuItem[] = [
        {
            id: 'account',
            label: 'Account',
            icon: 'fas fa-user',
            isVisible: true,
            link: accountLink.value
        },
        {
            id: 'helpCenter',
            label: 'Help Center',
            icon: 'fas fa-question',
            isVisible: true,
            link: helpCenterLink.value
        },
        {
            id: 'makeReferral',
            label: 'Make a Referral',
            icon: 'far fa-gift',
            isVisible: true,
            link: 'https://bit.ly/2Yz7Fxb'
        },
        {
            id: 'logOut',
            label: 'Log out',
            icon: 'far fa-arrow-right-from-bracket',
            isVisible: true,
            link: '/accounts/v1/logout/?next=/login&errnext=/login'
        }
    ]

    if (showInternalTools.value) {
        items.unshift({
            id: 'internalTools',
            label: 'Internal Tools',
            icon: 'fas fa-screwdriver-wrench',
            isVisible: true,
            link: '/util/internal_tools'
        })
    }

    if (userCanMasquerade.value) {
        items.unshift({
            id: 'switchUsers',
            label: 'Switch Users',
            icon: 'fas fa-users',
            isVisible: true,
            handleClick: toggleMasqueradeUserSelect
        })
    }
    return items
})

const toggleMasqueradeUserSelect = (event: Event): void => {
    masqueradePopover.value.toggle(event)
}

const updateMasqueradeUser = async (username: string): Promise<void> => {
    try {
        await userStore.setMasqueradeUser(username)
    } catch (error) {
        console.error('Failed to masquerade into user with username ', username)
    }
}

const showInternalTools = computed(() => {
    return isSuperUser.value || isStaff.value || isThirdParty.value
})

const userCanMasquerade = computed(() => {
    return masqueradeRoles.value.length > 0
})

const isMasqUser = (username: string): boolean => {
    return username === currentMasqueradeUser.value
}

const toggleMenu = (event: Event): void => {
    menu.value.toggle(event)
}
</script>
