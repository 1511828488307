<template>
    <div class="bg-white p-8 m-8 rounded-lg shadow-md w-96">
        <div class="flex justify-center mb-6">
            <img src="@/assets/images/performline-logo-blue.png" alt="Performline" class="h-12" />
        </div>

        <h1 class="text-xl font-semibold text-center mb-4">Password Reset</h1>

        <div v-if="resetError" class="bg-red-500/20 text-red-600 text-left mb-4 px-4 py-2 text-sm">
            {{ resetError }}
        </div>

        <div v-if="showSuccessMessage" class="mb-4">
            <p class="font-semibold">Success! We'll send you an email allowing you to reset your password.</p>
        </div>
        <div v-else>
            <p class="text-gray-700 mb-6 text-sm">
                Enter the email address associated with your account below, and we'll send you an email allowing you to
                reset your password.
            </p>

            <form class="w-full" @submit.prevent="handleSubmit">
                <div class="mb-4">
                    <label for="email" class="block text-gray-600 mb-1 text-sm font-semibold">Email*</label>
                    <input
                        id="email"
                        v-model="email"
                        type="email"
                        required
                        placeholder="Email address"
                        class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                        @blur="validateEmail"
                    />
                    <span v-if="emailError" class="text-red-500 text-sm">{{ emailError }}</span>
                </div>

                <Button
                    rounded
                    type="submit"
                    class="w-full bg-blue-500 text-white py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 mb-3"
                    :disabled="isSubmitting"
                >
                    {{ isSubmitting ? 'Sending...' : 'Reset my password' }}
                </Button>
                <div class="text-center mb-4">
                    <router-link to="/login" class="text-blue-500 hover:underline text-sm">
                        Return to Login
                    </router-link>
                </div>
            </form>
        </div>

        <p class="text-gray-600 text-sm">
            If you have any trouble resetting your password, email us at
            <a href="mailto:support@performline.com" class="text-blue-500 hover:underline">support@performline.com</a>.
        </p>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useUserStore } from '@/stores/useUserStore'
import { useRoute } from 'vue-router'

const route = useRoute()
const userStore = useUserStore()

const email = ref('')
const emailError = ref('')
const showSuccessMessage = ref(false)
const resetError = ref('')
const isSubmitting = ref(false)

onMounted((): void => {
    const errorMsg = route.query.error as string
    if (errorMsg) {
        resetError.value = errorMsg
    }
})

const validateEmail = (): void => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    emailError.value = emailRegex.test(email.value) ? '' : 'Please enter a valid email address'
}

const handleSubmit = async (): Promise<void> => {
    validateEmail()
    if (emailError.value) return

    emailError.value = ''
    showSuccessMessage.value = false
    resetError.value = ''
    isSubmitting.value = true

    try {
        await userStore.forgotPassword(email.value)
        showSuccessMessage.value = true
        email.value = '' // Clear the email input after successful submission
    } catch (error: any) {
        resetError.value = error.message
    } finally {
        isSubmitting.value = false
    }
}
</script>
