export function stringToBool(val: string): boolean {
    return val === 'true' ? true : false
}

/**
 * Validates orderBy value taken from URL query. Ensures it exists within the passed enum
 * @param orderBy extracted from URL, determines how to order a table/data view
 * @param sortOrder extracted from URL, either -1 (dsc), 1 (asc), or undefined (no sort)
 * @param enumType enum to check against, should contain the orderBy option
 * @returns the orderBy option as a key of the passed enum
 */
export function validateAndComputeOrderBy<T extends Record<string, string>>(
    orderBy: string | undefined,
    sortOrder: number | undefined,
    enumType: T
): `${T[keyof T]}` | undefined {
    if (!orderBy) {
        return undefined
    }
    // Extract the values from the Enum type
    const enumValues = Object.values(enumType) as string[]
    // Determine the orderBy option based on the sort order num (-1 is desc, 1 is asc)
    const computedOrderBy = sortOrder === -1 ? `-${orderBy}` : orderBy

    // If the passed enum values don't include the computedOrder by, then it is not a valid value
    if (!enumValues.includes(computedOrderBy)) {
        throw new Error(`Invalid orderBy value: ${computedOrderBy}`)
    }

    // Return the computed orderBy option as a key of the passed enum
    return computedOrderBy as `${T[keyof T]}`
}
/**
 * Determines whether the value(s) exist in the enum 
 * @param input check if this string or all strings exist within an enum
 * @param enumType enum to check against
 * @returns true if value exists in enum, false if not
 */
export function validateEnum<T extends Record<string, string>>(input: string | string[], enumType: T): boolean {
    if (!input) {
        return false
    }
    let inputArray = []
    if (Array.isArray(input)) {
        inputArray = [...input]
    } else {
        inputArray = [...input.split(',')]
    }
    const validValues = Object.values(enumType)
    return inputArray.every((value) => validValues.includes(value))
}

/**
 * Capitalize first letter of a string
 * @param str string to capitalize first letter of
 */
export function capitalizeFirstLetter(str: string): string {
    return String(str).charAt(0).toUpperCase() + String(str).slice(1)
}

export function camelCaseToTitleCase(str: string): string {
    if (/^[a-z]+$/.test(str)) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (match) {
        return match.toUpperCase()
    })
}

/**
 * convert string into base64 url encoding
 * @param value string to be encoded
 * @returns encoded string between 0 and 22 characters
 */
export function base64url(value: string): string {
    const noHyphens = value.replace(/-/g, "");
    const byteArray = new Uint8Array(noHyphens.match(/.{2}/g)?.map((hex) => parseInt(hex, 16)) || []);
    let base64UrlEncoded = btoa(String.fromCharCode(...byteArray));
    // Adjust for URL-safe base64 encoding (replace + with -, / with _, and remove padding)
    base64UrlEncoded = base64UrlEncoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, "");
    return base64UrlEncoded.slice(0, 22);
}

/**
 * Compares two arrays for equality of their elements, regardless of order
 * @param arr1 first array to compare
 * @param arr2 second array to compare
 * @returns true if arrays contain the same elements, false otherwise
 */
export function isArrayEqual<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) return false
    return arr1.every((item) => arr2.includes(item)) && arr2.every((item) => arr1.includes(item))
}
