import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import LaraPline from '@/presets/lara-pline' // eslint-disable-line @typescript-eslint/no-explicit-any
import type { App, Plugin } from 'vue'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'

interface PrimeVueOptions {
    unstyled: boolean
    pt: any
    ptOptions: any
}

const defaultOptions: PrimeVueOptions = {
    unstyled: true,
    pt: LaraPline,
    ptOptions: {
        mergeSections: true,
        mergeProps: true
    }
}

const PrimeVuePlugin: Plugin = {
    install(app: App, options: PrimeVueOptions = defaultOptions) {
        app.use(PrimeVue, options)
        app.directive('tooltip', Tooltip)
        app.use(ConfirmationService)
        app.use(ToastService)
    }
}

export default PrimeVuePlugin
