<template>
    <TableFilter
        :name="'feature'"
        :label="'Feature'"
        :is-active="isFilterActive"
        :enable-clear-filter="true"
        @clear-filter="onRemoveFilter"
    >
        <template #icon="{ variant }">
            <FaIcon icon="far fa-list" :class="variant"></FaIcon>
        </template>

        <template #value>
            <div
                v-if="isFilterActive"
                class="text-sm m-w-40 text-ellipsis overflow-hidden"
                data-testid="feature-filter-display"
            >
                {{ featuresInLabel }}
            </div>
        </template>

        <template #content>
            <div :key="componentKey" class="grid gap-2 my-4 w-full">
                <div
                    v-for="feature in featureOptions"
                    :key="feature.name"
                    class="flex align-center w-full text-sm py-1 px-4"
                >
                    <Checkbox
                        v-model="selectedFeatures"
                        :input-id="feature.name"
                        :name="feature.name"
                        :value="feature.name"
                        :pt="{
                            root: { class: ['m-auto !h-4 !w-4 !py-0 '] },
                            box: { class: ['!h-4 !w-4 !rounded'] }
                        }"
                    />
                    <label :for="feature.name" class="flex w-full hover:cursor-pointer">
                        <span class="ml-4">{{ feature.label }}</span>
                    </label>
                </div>
            </div>
        </template>
    </TableFilter>
</template>

<script lang="ts" setup>
import { computed, ref, watch, type PropType } from 'vue'
import TableFilter from '@/components/dataTable/tableFilter/TableFilter.vue'
import { camelCaseToTitleCase } from '@/utils/helpers'
import { HelpCenterFeature } from '@/interfaces/HelpCenter.types'

export interface FeatureOption {
    name: HelpCenterFeature
    label: string
    isSelected: boolean
}

const props = defineProps({
    features: {
        type: Array as PropType<HelpCenterFeature[]>,
        required: false,
        default: undefined
    }
})

const emit = defineEmits(['update-feature-filter', 'remove-feature-filter'])

const featureOptions = ref<FeatureOption[]>(
    Object.values(HelpCenterFeature).map((feature) => ({
        name: feature,
        label: camelCaseToTitleCase(feature),
        isSelected: false
    }))
)

const selectedFeatures = ref<string[]>([])

watch(
    () => props.features,
    (payload: HelpCenterFeature[] | undefined) => {
        setTimeout(() => {
            if (payload) {
                const isDifferent =
                    payload.length !== selectedFeatures.value.length ||
                    payload.some((feature, index) => feature !== selectedFeatures.value[index])
                if (isDifferent) {
                    selectedFeatures.value = payload
                }
            } else if (selectedFeatures.value.length > 0) {
                selectedFeatures.value = []
            }
        }, 0)
    }
)

const componentKey = ref(0)

watch(
    () => selectedFeatures.value,
    (features: string[]) => {
        componentKey.value += 1
        setTimeout(() => {
            emit('update-feature-filter', { features: features.length > 0 ? features : undefined })
        }, 0)
    }
)

const onRemoveFilter = (): void => {
    emit('remove-feature-filter')
}

const isFilterActive = computed(() => {
    return selectedFeatures.value.length > 0
})

const featuresInLabel = computed(() => {
    return selectedFeatures.value.map((feature) => camelCaseToTitleCase(feature)).join(', ')
})
</script>
